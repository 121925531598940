import { get as getCookie, remove as removeCookie } from 'es-cookie';

export interface JWTPayload {
    uuid: string;
    role: 'teacher' | 'student' | 'admin';
    exp: number;
}

export function decodeToken(token: string): JWTPayload {
    const payload = token.split('.')[1];
    return JSON.parse(atob(payload));
}

export function checkTokenIsValid(token: string): boolean {
    if (token) {
        const decodedPayload = decodeToken(token);
        const oneHour = 60 * 60 * 1000;
        const expire = new Date(decodedPayload.exp * 1000 - oneHour);

        return expire > new Date();
    }
    return false;
}

export function onValidToken(): Promise<string> {
    return new Promise(
        (resolve, reject): any => {
            const token = getCookie('token');

            if (checkTokenIsValid(token)) resolve(token);
            else {
                removeCookie('token');
                reject(new Error('Token Expired'));
            }
        }
    );
}
