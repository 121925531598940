import React, { ReactElement, useState, useEffect } from 'react';
import { Row, Label, Spinner } from 'reactstrap';
import SemesterInfoButton from './SemesterInfoButton';
import LabGroupInfoButton from './LabGroupInfoButton';
import AddLabGroupButton from './AddLabGroupButton';
import { Semester } from '../../../helpers/requests/interfaces/semesterInterfaces';
import { LabGroupInformation } from '../../../helpers/requests/interfaces/labGroupInterfaces';
import { getLabGroups, postLabGroup } from '../../../helpers/requests/labGroupRequests';

export default function SemesterRow({
    semester,
    addLabGroupNotifier,
}: {
    semester: Semester;
    addLabGroupNotifier: () => void;
}): ReactElement {
    const [labGroups, setLabGroups] = useState<LabGroupInformation[]>([]);
    const [loading = true, setLoading] = useState<boolean>();

    useEffect((): void => {
        getLabGroups(semester.labUUID, semester.uuid).then(
            (data): void => {
                setLabGroups(data);
                setLoading(false);
            }
        );
    }, []);

    return (
        <>
            {!loading ? (
                <tr className="d-flex">
                    <td className="col-3 text-center" style={{ borderRight: '2px solid rgba(0, 0, 0, 0.06)' }}>
                        <SemesterInfoButton classname="mx-3" semester={semester} />
                    </td>
                    <td className="col text-left">
                        {labGroups &&
                            labGroups.map(
                                (labgroup): ReactElement => (
                                    <LabGroupInfoButton classname="mx-3" labgroup={labgroup} key={labgroup.uuid} />
                                )
                            )}
                        <AddLabGroupButton
                            submit={(event: any): Promise<void> => {
                                event.preventDefault();
                                const labGroupName = event.target.elements.labGroupName.value;

                                return postLabGroup(semester.labUUID, semester.uuid, labGroupName).then(
                                    (labGroup): void => {
                                        setLabGroups([...labGroups, labGroup]);
                                    }
                                );
                            }}
                            handler={addLabGroupNotifier}
                        />
                    </td>
                </tr>
            ) : (
                <>
                    <tr className="d-flex">
                        <td className="col-3 text-center" style={{ borderRight: '2px solid rgba(0, 0, 0, 0.06)' }}>
                            <Spinner animation="border" role="status" />
                        </td>
                    </tr>
                </>
            )}
        </>
    );
}
