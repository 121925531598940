import React, { ReactElement, useState } from 'react';
import { Button, Form, Input, Row, Col, FormGroup, Label } from 'reactstrap';

interface InputState {
    name: string;
}

export default function AddLabGroupButton({
    submit,
    handler,
}: {
    submit: (event: any) => Promise<void>;
    handler: () => void;
}): ReactElement {
    const [inCreate, setInCreate] = useState(false);

    const [inputState, setInputState] = useState<InputState>({
        name: '',
    });

    return (
        <>
            {inCreate ? (
                <Form
                    className="bg-info w-25 mb-1 mx-2 p-2"
                    style={{ borderRadius: '0.2rem', display: 'inline-block' }}
                    onSubmit={(event): void => {
                        submit(event).then(
                            (): void => {
                                setInCreate(false);
                            }
                        );
                    }}
                >
                    <Col xs={{ size: 10, offset: 1 }}>
                        <Row className="mb-3">
                            <FormGroup className="my-0 pb-1">
                                <Label for="labGroupName" hidden style={{ display: 'none' }}>
                                    Lab group name
                                </Label>
                                <Input
                                    id="labGroupName"
                                    className="pl-3 pr-1 py-0"
                                    type="text"
                                    name="labGroupName"
                                    placeholder="LabGroup name"
                                    autoFocus
                                    onChange={(event): void => {
                                        setInputState({ name: event.target.value });
                                    }}
                                />
                            </FormGroup>
                        </Row>
                        <Row className="mb-2">
                            <Col xs={6} style={{ padding: '0' }}>
                                {inputState.name.length > 0 ? (
                                    <Button className="px-2" type="submit" onClick={handler} color="primary">
                                        Create
                                    </Button>
                                ) : (
                                    <Button className="px-2" type="submit" color="primary" disabled>
                                        Create
                                    </Button>
                                )}
                            </Col>
                            <Col xs={6} style={{ padding: '0' }}>
                                <Button
                                    className="px-2"
                                    type="button"
                                    color="danger"
                                    onClick={(): void => {
                                        setInCreate(false);
                                    }}
                                >
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Form>
            ) : (
                <Button
                    onClick={(): void => {
                        setInCreate(true);
                    }}
                    color="primary"
                >
                    Add lab group
                </Button>
            )}
        </>
    );
}
