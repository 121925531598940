import React, { ReactElement } from 'react';
import { Row } from 'reactstrap';
import VMStateCard from '../../components/Card/VMStateCard';

export default function VMStatusPage(): ReactElement {
    /* Plan:
        In the future this dashboard shall be an overview of all VMs of one student. 
        Each Card displays the state of one VM and has a download button for the RPM file of that VM.
        The width of the cards is dependent of the amount of cards:
            - 1 card: w-75
            - 2 cards: w-50
            - 3 cards: w-30
            - more than 3 cards will have w-30 and be displayed in multiple rows with each row containing up to 3 cards.
    */
    return (
        <Row className=" mt-5 justify-content-around">
            <VMStateCard vmId="<enterid>" size="w-50" />
        </Row>
    );
}
