import React, { ReactElement } from 'react';
import { Row, Col, Label, Spinner } from 'reactstrap';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Breadcrumbs from 'react-router-dynamic-breadcrumbs';
import DataTable, { ColumnsJSON, SemesterRowSelectInformation } from '../../components/Teacher/DataTables/DataTable';
import InfoButton from '../../components/Button/InfoButton';
import DeleteButton from '../../components/Button/DeleteButton';
import { Lab } from '../../helpers/requests/interfaces/labInterfaces';
import { getLabs } from '../../helpers/requests/labRequests';
import ConfirmModal from '../../components/Modal/ConfirmModal';
import { getSemesters, deleteSemester } from '../../helpers/requests/semesterRequests';
import { Semester } from '../../helpers/requests/interfaces/semesterInterfaces';

interface SemestersState {
    inCreate: boolean;
    labs: Lab[];
    semesters: Semester[];
    semesterDisplayData: SemesterDisplayData[];
    showDeleteConfirmation: boolean;
    deleteUuid: string;
    deleteLabUuid: string;
    loading: boolean;
}

interface SemesterDisplayData {
    lab: string;
    semester: string;
    uuid: string;
    labUuid: string;
}

class Semesters extends React.Component<RouteComponentProps, SemestersState> {
    private columns: ColumnsJSON[] = [
        {
            dataField: 'semester',
            text: 'Semester',
            sort: true,
        },
        {
            dataField: 'lab',
            text: 'Lab',
            sort: true,
        },
        {
            dataField: 'uuid',
            text: 'uuid',
            hidden: true,
        },
        {
            dataField: 'df1',
            isDummyField: true,
            text: 'Actions',
            formatter: (cellContent: any, row: SemesterRowSelectInformation): ReactElement => {
                return (
                    <>
                        <InfoButton
                            id={row.uuid}
                            tooltipText="Show more information"
                            className="mr-3"
                            link={`/labs/${row.labUuid}/semesters/${row.uuid}`}
                        />
                        <DeleteButton
                            id={row.uuid}
                            tooltipText="Delete Semester"
                            className="ml-3"
                            onClick={(): void => {
                                this.setState(
                                    (prevState): SemestersState => ({
                                        ...prevState,
                                        showDeleteConfirmation: true,
                                        deleteLabUuid: row.labUuid,
                                        deleteUuid: row.uuid,
                                    })
                                );
                            }}
                        />
                    </>
                );
            },
        },
    ];

    public constructor(props: RouteComponentProps) {
        super(props);
        this.state = {
            inCreate: false,
            labs: [],
            semesters: [],
            semesterDisplayData: [],
            showDeleteConfirmation: false,
            deleteUuid: '',
            deleteLabUuid: '',
            loading: false,
        };
    }

    public async componentWillMount(): Promise<void> {
        this.setState({ loading: true });
        await getLabs()
            .then(
                (labs: Lab[]): void => {
                    this.setState({ labs });
                }
            ).finally(
                ():void=>{this.setState({loading: false});}
            );
        this.composeSemesterDataDisplay();
    }

    public hideDeleteModal = (): void => {
        this.setState({ showDeleteConfirmation: false });
    };

    public deleteLabsemester = (): void => {
        const { deleteLabUuid } = this.state;
        const { deleteUuid } = this.state;

        deleteSemester(deleteLabUuid, deleteUuid).then(
            (): void => {
                const { semesters } = this.state;
                const newSemesters = semesters.filter((semester): boolean => semester.uuid !== deleteUuid);

                this.setState(
                    (prevState): SemestersState => ({
                        ...prevState,
                        semesters: newSemesters,
                        showDeleteConfirmation: false,
                    })
                );
                this.composeSemesterDataDisplay();
            }
        );
    };

    private composeSemesterDataDisplay(): void {
        const { labs } = this.state;
        const semesterDisplayData = new Array<SemesterDisplayData>();

        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        labs.forEach((lab: Lab) => {
            getSemesters(lab.uuid).then(
                (semesters: Semester[]): void => {
                    this.setState({ semesters });
                    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
                    semesters.forEach((semester: Semester) => {
                        const item = {
                            semester: semester.name,
                            lab: lab.name,
                            uuid: semester.uuid,
                            labUuid: lab.uuid,
                        };
                        semesterDisplayData.push(item);

                        this.setState(
                            (prevState): SemestersState => ({
                                ...prevState,
                            })
                        );
                    });
                }
            );
        });

        this.setState({ semesterDisplayData });
    }

    public render(): ReactElement {
        const { semesterDisplayData, showDeleteConfirmation, deleteUuid, loading } = this.state;
        const semesterForDeletion = semesterDisplayData.find((semester): boolean => semester.uuid === deleteUuid);
        return (
            <>
                <Breadcrumbs
                    mappedRoutes={{
                        '/': 'Home',
                        '/semesters': 'Semesters',
                    }}
                />
                <Row className="justify-content-center">
                    <h2>Semesters</h2>
                </Row>
                {!loading ? (
                    <Row className="p-2 mt-3">
                        <Col className="text-center">
                            <DataTable
                                displayData={semesterDisplayData}
                                columns={this.columns}
                                onRowClick={(event: any, row: SemesterDisplayData): void => {
                                    const { history } = this.props;
                                    history.push(`/labs/${row.labUuid}/semesters/${row.uuid}`);
                                }}
                            />
                        </Col>
                    </Row>
                ) : (
                    <>
                        <Row className="justify-content-center">
                            <Label>
                                <h4>Loading Semesters...</h4>
                            </Label>
                        </Row>
                        <Row className="justify-content-center">
                            <Spinner animation="border" role="status" />
                        </Row>
                    </>
                )}
                <ConfirmModal
                    show={showDeleteConfirmation}
                    handleClose={this.hideDeleteModal}
                    action={this.deleteLabsemester}
                    type="delete"
                    itemsForDeletion={
                        semesterForDeletion
                            ? [
                                  {
                                      type: 'Semester',
                                      name: semesterForDeletion.semester,
                                  },
                              ]
                            : null
                        // TODO: add all semesters, labgroups and students in this lab to warning
                    }
                />
            </>
        );
    }
}

export default withRouter(Semesters);
