import React, { ReactElement } from 'react';
import { Button, UncontrolledTooltip } from 'reactstrap';
import Create from '@material-ui/icons/Create';
import { Link } from 'react-router-dom';

export default function EditButton({
    link,
    text,
    className,
    tooltipText,
    id,
}: {
    link: string;
    text?: string;
    className?: string;
    tooltipText?: string;
    id?: string;
}): ReactElement {
    return (
        <>
            <Button
                className={`${className} p-2`}
                color="primary"
                tag={Link}
                to={link}
                id={id !== undefined ? `editButton_${id}` : 'editButton'}
                onClick={(event: any): void => {
                    event.stopPropagation();
                }}
            >
                <Create />
                {text && <span>{text}</span>}
            </Button>
            {id && tooltipText && (
                <UncontrolledTooltip placement="top" target={`editButton_${id}`}>
                    {tooltipText}
                </UncontrolledTooltip>
            )}
        </>
    );
}
