import React, { ReactElement } from 'react';
import { Card, CardTitle, CardText, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Page } from '../App/App';

interface PageCardProps {
    page: Page;
    text?: string;
}

function PageCard({ page, text }: PageCardProps): ReactElement {
    return (
        <Card body>
            <CardTitle className="mx-auto">{page.name}</CardTitle>
            {text && <CardText>{text}</CardText>}
            <Button tag={Link} to={page.link} color="primary">
                View {page.name}
            </Button>
        </Card>
    );
}

export default PageCard;
