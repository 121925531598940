import React, { ReactElement, Component } from 'react';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import ConfirmVmTerminateModal from '../Modal/ConfirmVmTerminateModal';
import ConfirmModal from '../Modal/ConfirmModal';

interface InstanceActionButtonProps {
    onSubmit: (event: any) => void;
    selected: any[];
    isDisabled: boolean;
}

interface InstanceActionButtonState {
    showInstanceConfirmationModal: boolean;
    showStudentConfirmationModal: boolean;
    action: string;
}

class InstanceActionButton extends Component<InstanceActionButtonProps, InstanceActionButtonState> {
    public constructor(props: InstanceActionButtonProps) {
        super(props);

        this.state = {
            showInstanceConfirmationModal: false,
            showStudentConfirmationModal: false,
            action: '',
        };
    }

    private doAction = (): void => {
        const { onSubmit } = this.props;
        const { action } = this.state;
        this.setState({ showInstanceConfirmationModal: false, showStudentConfirmationModal: false });
        onSubmit(action);
    };

    private hideConfirmationModal = (): void => {
        this.setState({ showInstanceConfirmationModal: false, showStudentConfirmationModal: false });
    };

    public render(): ReactElement {
        const { onSubmit, selected, isDisabled } = this.props;
        const { showInstanceConfirmationModal, showStudentConfirmationModal, action } = this.state;

        return (
            <>
                <UncontrolledButtonDropdown
                    className={isDisabled ? 'Justify-content-center btn-disabled' : 'Justify-content-center'}
                    disabled={isDisabled}
                >
                    <DropdownToggle caret color="primary" className="float-right h-100 w-100">
                        Actions
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem header>Selected instances</DropdownItem>
                        <DropdownItem
                            disabled={isDisabled}
                            value="start"
                            onClick={(): void => {
                                onSubmit('start');
                            }}
                        >
                            Start
                        </DropdownItem>
                        <DropdownItem
                            disabled={isDisabled}
                            value="stop"
                            onClick={(): void => {
                                onSubmit('stop');
                            }}
                        >
                            Stop
                        </DropdownItem>
                        <DropdownItem
                            disabled={isDisabled}
                            value="recreate"
                            onClick={(): void => {
                                this.setState({
                                    showInstanceConfirmationModal: true,
                                    action: 'recreate',
                                });
                            }}
                        >
                            Recreate
                        </DropdownItem>
                        <DropdownItem
                            disabled={isDisabled}
                            value="terminate"
                            onClick={(): void => {
                                this.setState({
                                    showInstanceConfirmationModal: true,
                                    action: 'terminate',
                                });
                            }}
                        >
                            Delete Instance
                        </DropdownItem>
                        <DropdownItem header>Selected students</DropdownItem>
                        <DropdownItem
                            disabled={isDisabled}
                            value="resetPassword"
                            onClick={(): void => {
                                this.setState({
                                    showStudentConfirmationModal: true,
                                    action: 'resetPassword',
                                });
                            }}
                        >
                            Reset Password
                        </DropdownItem>
                        <DropdownItem
                            disabled={isDisabled}
                            value="delete"
                            onClick={(): void => {
                                this.setState({
                                    showStudentConfirmationModal: true,
                                    action: 'delete',
                                });
                            }}
                        >
                            Delete Student
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledButtonDropdown>
                {(action === 'recreate' || action === 'terminate') && (
                    <ConfirmVmTerminateModal
                        show={showInstanceConfirmationModal}
                        handleClose={this.hideConfirmationModal}
                        deleteAction={this.doAction}
                        type={action}
                        itemsForTermination={
                            selected
                                ? [
                                      ...selected.map(
                                          (item): { type: string; name: string } => ({
                                              type: 'Student Instance',
                                              name: item.token,
                                          })
                                      ),
                                  ]
                                : null
                        }
                        disabled={false}
                    />
                )}
                {(action === 'recreate' || action === 'terminate') && (
                    <ConfirmVmTerminateModal
                        show={showInstanceConfirmationModal}
                        handleClose={this.hideConfirmationModal}
                        deleteAction={this.doAction}
                        type={action}
                        itemsForTermination={
                            selected
                                ? [
                                      ...selected.map(
                                          (item): { type: string; name: string } => ({
                                              type: 'Student Instance',
                                              name: item.token,
                                          })
                                      ),
                                  ]
                                : null
                        
                            }
                        disabled={false}
                    />
                )}
                {(action === 'delete' || action === 'resetPassword') && (
                    <ConfirmModal
                        show={showStudentConfirmationModal}
                        handleClose={this.hideConfirmationModal}
                        action={this.doAction}
                        type={action}
                        itemsForDeletion={
                            selected
                                ? [
                                      ...selected.map(
                                          (item): { type: string; name: string } => ({
                                              type: 'Student',
                                              name: item.token,
                                          })
                                      ),
                                  ]
                                : null
                        }
                    />
                )}
            </>
        );
    }
}

export default InstanceActionButton;
