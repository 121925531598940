import React, { useState, ReactElement } from 'react';
import { Table, Button } from 'reactstrap';
import { Lab } from '../../helpers/requests/interfaces/labInterfaces';

export default function LabInfoTable(props: { lab: Lab; className?: string }): ReactElement {
    const [showConfig, setShowConfig] = useState(false);
    const { lab, className } = props;

    return (
        <Table className={className}>
            <tbody>
                {lab && (
                    <>
                        {Object.keys(lab)
                            .filter((key): boolean => key !== 'config')
                            .map(
                                (key): ReactElement => (
                                    <tr key={key}>
                                        <td className="text-left pl-5" style={{ fontWeight: 'bold' }}>{`${key
                                            .charAt(0)
                                            .toUpperCase() + key.substr(1)}: `}</td>
                                        <td className="text-left pl-5">{lab[key]}</td>
                                    </tr>
                                )
                            )}
                    </>
                )}
            </tbody>
        </Table>
    );
}
