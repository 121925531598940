import React, { ReactElement, useState } from 'react';
import { Table, Button } from 'reactstrap';
import { VMInstanceInfo } from '../../helpers/requests/interfaces/studentInterfaces';

export default function VMInstanceStatusDisplays({ instance }: { instance: VMInstanceInfo }): ReactElement {
    const visibleInfos = ['Platform', 'Architecture'];
    const [showMore, setShowMore] = useState(false);

    let color: 'green' | 'red' | 'gray';
    if (instance.State === 'running') color = 'green';
    else if (instance.State === 'stopped') color = 'red';
    else color = 'gray';

    return (
        <>
            <Table className="w-75 mx-auto">
                <tbody>
                    {Object.keys(instance)
                        .filter((key): boolean => visibleInfos.includes(key))
                        .map(
                            (key): ReactElement => {
                                return (
                                    <tr key={key}>
                                        <td className="text-left pl-4" style={{ fontWeight: 'bold' }}>{`${key}: `}</td>
                                        <td className="text-left pl-5">{instance[key]}</td>
                                    </tr>
                                );
                            }
                        )}
                    {showMore &&
                        Object.keys(instance)
                            .filter(
                                (key): boolean =>
                                    !visibleInfos.includes(key) && !(instance[key] === '') && !(key === 'State')
                            )
                            .map(
                                (key): ReactElement => {
                                    return (
                                        <tr key={key}>
                                            <td
                                                className="text-left pl-4"
                                                style={{ fontWeight: 'bold' }}
                                            >{`${key}: `}</td>
                                            <td className="text-left pl-5">{instance[key]}</td>
                                        </tr>
                                    );
                                }
                            )}
                </tbody>
            </Table>
            <Button
                onClick={(): void => {
                    setShowMore(!showMore);
                }}
            >
                {showMore ? 'show less' : 'show more'}
            </Button>

            <h2 className="w-50 mx-auto mt-5 pt-4" style={{ color }}>
                {instance.State}
            </h2>
        </>
    );
}
