import React, { ReactElement, useState, useEffect } from 'react';
import { Alert } from 'reactstrap';

export default function DisplayOrientationAlert(): ReactElement {
    const [showAlert, setShowAlert] = useState(false);

    useEffect((): void => {
        if (window.outerWidth < window.outerHeight) {
            setShowAlert(true);
            setTimeout((): void => {
                setShowAlert(false);
            }, 5000);
        }
    }, []);

    window.addEventListener(
        'orientationchange',
        (): void => {
            if (window.screen.width < window.screen.height) {
                setShowAlert(true);
                setTimeout((): void => {
                    setShowAlert(false);
                }, 5000);
            }
        }
    );

    return (
        <>
            {showAlert && (
                <Alert color="warning">
                    Please make sure to use the device in landscape mode (horizontally) to get the best user experience.
                </Alert>
            )}
        </>
    );
}
