import React, { Component, ReactElement } from 'react';
import { Card, CardTitle, Button, Row, Col, UncontrolledTooltip, Spinner, Label } from 'reactstrap';
import { saveAs } from 'file-saver';
import { History } from 'history';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import VMInstanceStatusDisplays from '../StatusDisplays/VMInstanceStatusDisplays';
import { VMInstanceActionButton } from '../Button/VMInstanceActionButton';
import { getStudentInfo } from '../../helpers/requests/studentRequests';
import { VMInstanceInfo } from '../../helpers/requests/interfaces/studentInterfaces';
import ConfirmVmTerminateModal from '../Modal/ConfirmVmTerminateModal';

interface VMStateCardProps extends RouteComponentProps {
    vmId: string;
    size: string;
}

interface VMStateCardState {
    VMInstance?: VMInstanceInfo;
    loading: boolean;
}

class VMStateCard extends Component<VMStateCardProps, VMStateCardState> {
    public constructor(props: VMStateCardProps) {
        super(props);

        this.state = { loading: true };
        this.fetchVMInfos();
    }

    private fetchVMInfos(): void {
        console.log('fetching vm infos');
        getStudentInfo()
            .then(
                (data): 'stopped' | 'pending' | 'running' | 'stopping' => {
                    console.log('TCL: VMStateCard -> data', data);
                    this.setState({ VMInstance: data, loading: false });
                    return data.State;
                }
            )
            .then(
                (state): void => {
                    if (!(state === 'stopped' || state === 'running')) {
                        setTimeout((): void => {
                            this.fetchVMInfos();
                        }, 5000);
                    }
                }
            )
            .catch(
                (error: any): void => {
                    const { history, location } = this.props;
                    if (error.response.data.status === 409) {
                        history.push({
                            pathname: '/change-password',
                            state: { firstLogin: true, redirect: location.pathname },
                        });
                        return;
                    }
                    if (error.response.data.status === 404) {
                        console.log(error.response);
                        this.setState({ loading: false });
                    }
                }
            );
    }

    private renderTerminateButton(): ReactElement {
        return (
            <>
                <VMInstanceActionButton
                    buttonText="Delete"
                    action="terminate"
                    id="terminateButton"
                    callback={(): void => {
                        setTimeout((): void => {
                            this.fetchVMInfos();
                        }, 1000);
                        // TODO: Think of a better solution
                        this.setState({ VMInstance: undefined });
                    }}
                />
                <UncontrolledTooltip placement="top" target="terminateButton">
                    Deletes your VM instance. You will have to create a new one manually. Consider doing this after you
                    have finished your work.
                </UncontrolledTooltip>
            </>
        );
    }

    public render(): ReactElement {
        const { vmId, size } = this.props;
        const { VMInstance, loading } = this.state;
        console.log(VMInstance);
        return (
            <Card className={`${size} text-center`} style={{ background: 'white' }}>
                <CardTitle className="h2 mt-3">VM Status</CardTitle>

                <div className="mt-4">
                    {!loading ? (
                        <>
                            {VMInstance ? (
                                <VMInstanceStatusDisplays instance={VMInstance} />
                            ) : (
                                <>
                                    <h4>VM Instance does not exist yet.</h4>
                                    <VMInstanceActionButton
                                        buttonText="Create VM"
                                        action="start"
                                        callback={(): void => {
                                            setTimeout((): void => {
                                                this.fetchVMInfos();
                                            }, 1000);
                                        }}
                                    />
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            <Row className="justify-content-center">
                                <Label>
                                    <h4>Loading VM Info...</h4>
                                </Label>
                            </Row>
                            <Row className="justify-content-center">
                                <Spinner animation="border" role="status" />
                            </Row>
                        </>
                    )}
                </div>

                <Row className="justify-content-center w-50 mb-4 mt-5 mx-auto">
                    {VMInstance && VMInstance.State === 'stopped' && (
                        <Row>
                            <Col>
                                <VMInstanceActionButton
                                    buttonText="Start"
                                    action="start"
                                    callback={(): void => {
                                        setTimeout((): void => {
                                            this.fetchVMInfos();
                                        }, 1000);
                                    }}
                                />
                            </Col>
                            <Col>{this.renderTerminateButton()}</Col>
                        </Row>
                    )}
                    {VMInstance && VMInstance.State === 'running' && (
                        <>
                            <Row>
                                <Button
                                    color="primary"
                                    onClick={(): void => {
                                        const rdpData = new Blob([
                                            'auto connect:i:1\n',
                                            `full address:s:${VMInstance.PublicDnsName}\n`,
                                            'username:s:Administrator\n',
                                        ]);
                                        saveAs(rdpData, 'CloudLab.rdp');
                                    }}
                                >
                                    Download RDP
                                </Button>
                            </Row>
                            <Row>
                                <Col>
                                    <VMInstanceActionButton
                                        buttonText="Stop"
                                        action="stop"
                                        id="stopButton"
                                        callback={(): void => {
                                            setTimeout((): void => {
                                                this.fetchVMInfos();
                                            }, 1000);
                                        }}
                                    />
                                </Col>
                                <Col>
                                    <VMInstanceActionButton
                                        buttonText="Recreate"
                                        action="recreate"
                                        id="recreateButton"
                                        callback={(): void => {
                                            setTimeout((): void => {
                                                this.fetchVMInfos();
                                            }, 1000);
                                        }}
                                    />
                                    <UncontrolledTooltip placement="top" target="recreateButton">
                                        Deletes your VM instance and creates a new one.Consider doing this if your VM
                                        instance has run into problems or stopped working even after restarting.
                                    </UncontrolledTooltip>
                                </Col>
                                <Col>
                                    <Col>{this.renderTerminateButton()}</Col>
                                </Col>
                            </Row>
                        </>
                    )}
                    {VMInstance && (VMInstance.State === 'pending' || VMInstance.State === 'stopping') && (
                        <>
                            <Spinner animation="border" role="status" />
                        </>
                    )}
                </Row>
            </Card>
        );
    }
}

export default withRouter(VMStateCard);
