import React, { ReactElement } from 'react';
import { Row, Col, Form, FormGroup, Label, Input, Button, FormText } from 'reactstrap';

interface ChangePasswordFormProps {
    onSubmit: (event: any) => void;
}

interface ChangePasswordFormState {
    oldPassword: string;
    newPassword: string;
    newPasswordConfirmation: string;
    passwordRequirementsMet: boolean;
    passwordFieldErrorMessage: string;
}

export default class ChangePasswordForm extends React.Component<ChangePasswordFormProps, ChangePasswordFormState> {
    public constructor(props: ChangePasswordFormProps) {
        super(props);
        this.state = {
            oldPassword: '',
            newPassword: '',
            newPasswordConfirmation: '',
            passwordRequirementsMet: false,
            passwordFieldErrorMessage: null,
        };
    }

    private onChange = (event: any): void => {
        const passwordInput: string = event.target.value;
        const passwordField: string = event.target.name;
        this.setState(
            (prevstate: ChangePasswordFormState): ChangePasswordFormState => ({
                ...prevstate,
                [passwordField]: passwordInput,
            })
        );
    };

    private onBlur = (event: any): void => {
        const password: string = event.target.value;
        if (password.length < 6) {
            this.setState({
                passwordRequirementsMet: false,
                passwordFieldErrorMessage: 'The password does not meet the required length.',
            });
            return;
        }
        const { oldPassword } = this.state;
        if (password === oldPassword) {
            this.setState({
                passwordRequirementsMet: false,
                passwordFieldErrorMessage: 'New password may not be equal to the old password.',
            });
            return;
        }
        this.setState({ passwordRequirementsMet: true, passwordFieldErrorMessage: null });
    };

    public render(): ReactElement {
        const { onSubmit } = this.props;
        const {
            oldPassword,
            newPassword,
            newPasswordConfirmation,
            passwordRequirementsMet,
            passwordFieldErrorMessage,
        } = this.state;
        return (
            <Form onSubmit={onSubmit}>
                <FormGroup>
                    <Row className="justify-content-start px-8">
                        <Col>
                            <Label for="oldPassword">
                                <span>Old password:</span>
                            </Label>
                            <Input
                                type="password"
                                name="oldPassword"
                                className="pl-3"
                                onChange={this.onChange}
                                value={oldPassword}
                                required
                            />
                        </Col>
                    </Row>
                    <Row className="justify-content-start px-8">
                        <Col>
                            <Label for="newPassword">
                                <span>New password:</span>
                            </Label>
                            <Input
                                type="password"
                                name="newPassword"
                                className="pl-3"
                                onChange={this.onChange}
                                value={newPassword}
                                onBlur={this.onBlur}
                                required
                            />
                            {!passwordRequirementsMet && (
                                <FormText color="danger"> {passwordFieldErrorMessage}</FormText>
                            )}
                            <FormText color="muted">Minimum length of 6 characters.</FormText>
                        </Col>
                    </Row>
                    <Row className="justify-content-start px-8">
                        <Col>
                            <Label for="newPasswordConfirmation">
                                <span>Confirm password:</span>
                            </Label>
                            <Input
                                type="password"
                                name="newPasswordConfirmation"
                                color="red"
                                className="pl-3"
                                onChange={this.onChange}
                                value={newPasswordConfirmation}
                                required
                            />
                            {newPassword !== newPasswordConfirmation && (
                                <FormText color="danger">The passwords do not match.</FormText>
                            )}
                        </Col>
                    </Row>
                </FormGroup>
                <Row />
                <Row>
                    <Col className="justify-content-end">
                        <Button
                            disabled={!passwordRequirementsMet || newPassword !== newPasswordConfirmation}
                            type="submit"
                            color="primary"
                        >
                            Send
                        </Button>
                    </Col>
                </Row>
            </Form>
        );
    }
}
