import React, { ReactElement } from 'react';
import { Row, Col, Label, Spinner } from 'reactstrap';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Breadcrumbs from 'react-router-dynamic-breadcrumbs';
import LabCreationForm from '../../components/Forms/LabCreationForm';
import DataTable, { ColumnsJSON, RowSelectInformation } from '../../components/Teacher/DataTables/DataTable';
import CreateButton from '../../components/Button/CreateButton';
import InfoButton from '../../components/Button/InfoButton';
import DeleteButton from '../../components/Button/DeleteButton';
import { onValidToken } from '../../helpers/tokenHelpers';
import { Lab } from '../../helpers/requests/interfaces/labInterfaces';
import { deleteLab, getLabs, postLabs } from '../../helpers/requests/labRequests';
import { getImages } from '../../helpers/requests/imageRequests';
import { Image } from '../../helpers/requests/interfaces/imageInterfaces';
import ConfirmModal from '../../components/Modal/ConfirmModal';

interface LabsState {
    inCreate: boolean;
    images: Image[];
    labs: Lab[];
    labDisplayData: LabDisplayData[];
    showDeleteConfirmation: boolean;
    deleteUuid: string;
    imageErrorText: string;
    loading: boolean;
}

interface LabDisplayData {
    name: string;
    abbreviation: string;
    password: string;
    image: string;
    uuid: string;
}
class Labs extends React.Component<RouteComponentProps, LabsState> {
    private columns: ColumnsJSON[] = [
        {
            dataField: 'name',
            text: 'Name',
            sort: true,
        },
        {
            dataField: 'abbreviation',
            text: 'Abbreviation',
            sort: true,
        },
        {
            dataField: 'password',
            text: 'Default Password',
            sort: true,
        },
        {
            dataField: 'image',
            text: 'Image',
            sort: true,
            formatter: (cellContent: any, row: RowSelectInformation): ReactElement => {
                const { imageErrorText } = this.state;
                return (
                    <>
                        <div className={imageErrorText === cellContent ? 'text-danger' : ''}>{cellContent}</div>
                    </>
                );
            },
        },
        {
            dataField: 'uuid',
            text: 'uuid',
            hidden: true,
        },
        {
            dataField: 'df1',
            isDummyField: true,
            text: 'Actions',
            formatter: (cellContent: any, row: RowSelectInformation): ReactElement => {
                return (
                    <>
                        <InfoButton
                            id={row.uuid}
                            tooltipText="Show more information"
                            className="mr-3"
                            link={`/labs/${row.uuid}`}
                        />
                        <DeleteButton
                            id={row.uuid}
                            tooltipText="Delete lab"
                            className="ml-3"
                            onClick={(): void => {
                                this.setState(
                                    (prevState): LabsState => ({
                                        ...prevState,
                                        showDeleteConfirmation: true,
                                        deleteUuid: row.uuid,
                                    })
                                );
                            }}
                        />
                    </>
                );
            },
        },
    ];

    public constructor(props: RouteComponentProps) {
        super(props);
        this.state = {
            inCreate: false,
            images: [],
            labs: [],
            labDisplayData: [],
            showDeleteConfirmation: false,
            deleteUuid: '',
            imageErrorText: 'Could not find Image! Please change Image.',
            loading: false,
        };
    }

    public async componentWillMount(): Promise<void> {
        this.setState({ loading: true });
        await onValidToken()
            .then(
                async (token): Promise<void> => {
                    await getImages()
                        .then(
                            (images: Image[]): void => {
                                this.setState({ images });
                            }
                        )
                        .catch(
                            (error: Error): void => {
                                console.error('Error when retreiving images!');
                                console.error(error);
                            }
                        );
                }
            )
            .catch(
                (error): void => {
                    console.log('please login');
                }
            );

        await getLabs()
            .then(
                (labs: Lab[]): void => {
                    this.setState({ labs });
                }
            )
            .catch(
                (error: Error): void => {
                    console.error('Error when retreiving images!');
                    console.error(error);
                }
            )
            .finally(
                (): void => {
                    this.setState({ loading: false });
                }
            );

        this.composeLabDataDisplay();
    }

    public hideDeleteModal = (): void => {
        this.setState({ showDeleteConfirmation: false });
    };

    public deleteLab = (): void => {
        const { deleteUuid } = this.state;
        deleteLab(deleteUuid).then(
            (): void => {
                const { labs } = this.state;
                const newLabs = labs.filter((lab): boolean => lab.uuid !== deleteUuid);

                this.setState(
                    (prevState): LabsState => ({ ...prevState, labs: newLabs, showDeleteConfirmation: false })
                );
                this.composeLabDataDisplay();
            }
        );
    };

    private composeLabDataDisplay(): void {
        const { labs, images, imageErrorText } = this.state;

        const labDisplayData = labs.map(
            (lab: Lab): LabDisplayData => {
                let name = imageErrorText;
                if (images.find((image: Image): boolean => image.ImageId === lab.imageId) !== undefined)
                    name = images.find((image: Image): boolean => image.ImageId === lab.imageId).Name;
                const item = {
                    image: name,
                    name: lab.name,
                    abbreviation: lab.abbreviation,
                    password: lab.password,
                    uuid: lab.uuid,
                };
                return item;
            }
        );
        this.setState({ labDisplayData });
    }

    public render(): ReactElement {
        const { inCreate, labDisplayData, showDeleteConfirmation, deleteUuid, loading } = this.state;
        const labForDeletion = labDisplayData.find((lab): boolean => lab.uuid === deleteUuid);
        return (
            <>
                <Breadcrumbs
                    mappedRoutes={{
                        '/': 'Home',
                        '/labs': 'Labs',
                        '/images': 'Images',
                    }}
                />
                <Row className="justify-content-center">
                    <h2>Labs</h2>
                </Row>
                {!inCreate && (
                    <Row className="my-2">
                        <Col sm={{ size: 3, offset: 9 }} lg={{ size: 2, offset: 10 }} className="float-right">
                            <CreateButton
                                onClick={(): void => {
                                    this.setState({ inCreate: true });
                                }}
                            />
                        </Col>
                    </Row>
                )}
                {inCreate && (
                    <LabCreationForm
                        onSubmit={(event: any): void => {
                            event.preventDefault();
                            const name = event.target.elements.name.value;
                            const abbreviation = event.target.elements.abbreviation.value;
                            const imageId = event.target.elements.imageId.value;
                            const password = event.target.elements.password.value;
                            const data = {
                                name,
                                abbreviation,
                                imageId,
                                password,
                            };
                            console.log('Create Lab:', data);

                            postLabs(data).then(
                                (newLab): void => {
                                    const { labs } = this.state;
                                    const newLabs = [...labs, newLab];

                                    this.setState(
                                        (prevState): LabsState => ({
                                            ...prevState,
                                            labs: newLabs,
                                            inCreate: false,
                                        })
                                    );
                                    this.composeLabDataDisplay();
                                }
                            );
                        }}
                        onCancel={(): void => {
                            this.setState({ inCreate: false });
                        }}
                    />
                )}
                <Row className="p-2 mt-3">
                    <Col className="text-center">
                        {!loading ? (
                            <DataTable
                                displayData={labDisplayData}
                                columns={this.columns}
                                onRowClick={(event: any, row: LabDisplayData): void => {
                                    const { history } = this.props;
                                    history.push(`/labs/${row.uuid}`);
                                }}
                            />
                        ) : (
                            <>
                                <Row className="justify-content-center">
                                    <Label>
                                        <h4>Loading Labs...</h4>
                                    </Label>
                                </Row>
                                <Row className="justify-content-center">
                                    <Spinner animation="border" role="status" />
                                </Row>
                            </>
                        )}
                    </Col>
                </Row>
                <ConfirmModal
                    show={showDeleteConfirmation}
                    handleClose={this.hideDeleteModal}
                    action={this.deleteLab}
                    type="delete"
                    itemsForDeletion={
                        labForDeletion
                            ? [
                                  {
                                      type: 'Lab',
                                      name: labForDeletion.name,
                                  },
                              ]
                            : null
                        // TODO: add all semesters, labgroups and students in this lab to warning
                    }
                />
            </>
        );
    }
}

export default withRouter(Labs);
