import Axios from 'axios';
import { get as getCookie } from 'es-cookie';
import { Lab } from './interfaces/labInterfaces';

export function getLabs(): Promise<Lab[]> {
    return Axios.get(`${process.env.API_URL}/labs`, {
        responseType: 'json',
        headers: { Authorization: `Bearer ${getCookie('token')}` },
    }).then(
        (response: any): Lab[] => {
            const { data } = response;
            return data;
        }
    );
}

export function postLabs(newLabData: {
    name: string;
    abbreviation: string;
    imageId: string;
    password: string;
}): Promise<Lab> {
    return Axios.post(`${process.env.API_URL}/labs`, newLabData, {
        responseType: 'json',
        headers: { Authorization: `Bearer ${getCookie('token')}` },
    }).then(
        (response: { data: Lab }): Lab => {
            const { data } = response;
            return data;
        }
    );
}

export function getLab(labUUID: string): Promise<Lab> {
    return Axios.get(`${process.env.API_URL}/labs/${labUUID}`, {
        responseType: 'json',
        headers: { Authorization: `Bearer ${getCookie('token')}` },
    })
        .then(
            (response: any): Lab => {
                const { data } = response;
                return data;
            }
        )
        .catch(
            (error: any): any => {
                return error;
            }
        );
}

export function putLab(
    labUUID: string,
    newLabData: {
        name: string;
        abbreviation: string;
        imageId: string;
        password: string;
    }
): Promise<void> {
    const url = `${process.env.API_URL}/labs/${labUUID}`;

    return Axios.put(url, newLabData, {
        responseType: 'json',
        headers: { Authorization: `Bearer ${getCookie('token')}` },
    }).then();
}

export function deleteLab(labUUID: string): Promise<void> {
    return Axios.delete(`${process.env.API_URL}/labs/${labUUID}`, {
        headers: { Authorization: `Bearer ${getCookie('token')}` },
    })
        .then(
            (): void => {
                console.log('Successfully deleted Lab');
            }
        )
        .catch(
            (error): void => {
                console.error(error);
            }
        );
}
