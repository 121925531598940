import React, { ReactElement } from 'react';
import {
    Row,
    Col,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    FormText,
} from 'reactstrap';

interface FeedbackFormProps {
    onSubmit: (event: any) => void;
}

interface FeedbackFormState {
    reason: string;
    otherReason?: string;
    details: string;
}

export default class FeedbackForm extends React.Component<FeedbackFormProps, FeedbackFormState> {
    private reasonDropdownOptions: string[] = ['BUG', 'IMPROVEMENT', 'OTHER'];

    public constructor(props: FeedbackFormProps) {
        super(props);
        this.state = {
            reason: '',
            otherReason: '',
            details: '',
        };
    }

    private onChange = (event: any): void => {
        const input: string = event.target.value;
        const field: string = event.target.name;
        this.setState(
            (prevstate: FeedbackFormState): FeedbackFormState => ({
                ...prevstate,
                [field]: input,
            })
        );
    };

    public render(): ReactElement {
        const { reasonDropdownOptions } = this;
        const { onSubmit } = this.props;
        const { reason, otherReason, details } = this.state;

        // TODO: Styling
        return (
            <Form onSubmit={onSubmit}>
                <FormGroup>
                    <Row className="justify-content-start px-4">
                        <Col md={4}>
                            <Label for="reason">
                                <span>Reason: </span>
                            </Label>
                            <input hidden type="hidden" name="reason" required value={reason || ''} />
                            <UncontrolledDropdown>
                                <DropdownToggle>{reason || 'Select a Reason'}</DropdownToggle>
                                <DropdownMenu>
                                    {this.reasonDropdownOptions.map(
                                        (reasonDropdownOption: string): ReactElement => (
                                            <DropdownItem
                                                key={reasonDropdownOption}
                                                onClick={(): void => {
                                                    this.setState({ reason: reasonDropdownOption });
                                                }}
                                            >
                                                {reasonDropdownOption}
                                            </DropdownItem>
                                        )
                                    )}
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Col>
                        <Col md={5}>
                            {reason === 'OTHER' && (
                                <>
                                    <Label for="otherReason">
                                        <span>Your reason:</span>
                                    </Label>
                                    <Input
                                        type="text"
                                        name="otherReason"
                                        className="pl-3"
                                        onChange={this.onChange}
                                        value={otherReason}
                                        required
                                    />
                                    {otherReason.length === 0 && (
                                        <FormText color="danger">Field may not be empty.</FormText>
                                    )}
                                </>
                            )}
                        </Col>
                    </Row>
                    {'  '}
                    <Row className="justify-content-start px-4">
                        <Label for="details">
                            <span>Details:</span>
                        </Label>
                        <Input
                            type="textarea"
                            name="details"
                            className="pl-3"
                            rows={6}
                            onChange={this.onChange}
                            value={details}
                            required
                        />
                        {details.length === 0 && <FormText color="danger">Field may not be empty.</FormText>}
                        {reason === 'BUG' && (
                            <FormText color="muted">
                                Please provide an explanation of the bug you experienced. It's especially helpful if you
                                could additionally provide the expected behaviour, the actual behaviour and possibly the
                                steps to reproduce the bug.
                            </FormText>
                        )}
                    </Row>
                </FormGroup>
                <Row>
                    <Col className="justify-content-end px-1">
                        <Button type="submit" color="primary" disabled={!reason}>
                            Send
                        </Button>
                    </Col>
                </Row>
            </Form>
        );
    }
}
