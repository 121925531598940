import React, { ReactElement } from 'react';
import {
    Row,
    Col,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';
import { getImages } from '../../helpers/requests/imageRequests';
import { Image } from '../../helpers/requests/interfaces/imageInterfaces';

interface LabEditFormProps {
    onSubmit: (event: any) => void;
    onCancel: () => void;
    labName: string;
    labAbbreviation: string;
    labImageId: string;
    labPassword: string;
}

interface LabEditFormState {
    images: Image[];
    selectedImage?: Image;
}

export default class LabEditForm extends React.Component<LabEditFormProps, LabEditFormState> {
    public constructor(props: LabEditFormProps) {
        super(props);

        this.state = {
            images: [],
        };
    }

    public componentWillMount(): void {
        const { labImageId } = this.props;
        getImages()
            .then(
                (images): void => {
                    const selectedImage = images.find((image): boolean => image.ImageId === labImageId);
                    this.setState({ images, selectedImage });
                }
            )
            .catch(
                (error: Error): void => {
                    console.log('Error when retrieving images!');
                    console.log(error);
                }
            );
    }

    public render(): ReactElement {
        const { onSubmit, onCancel, labAbbreviation, labName, labPassword } = this.props;
        const { images } = this.state;
        const { selectedImage } = this.state;

        return (
            <Form onSubmit={onSubmit}>
                <Row>
                    <Col md={{ size: 4, offset: 1 }}>
                        <Row>
                            <FormGroup className="w-100">
                                <Label for="labName">Name</Label>
                                <Input type="text" name="name" defaultValue={labName} required />
                            </FormGroup>
                        </Row>
                        <Row>
                            <FormGroup className="w-100">
                                <Label for="labAbbreviation">Abbreviation</Label>
                                <Input type="text" name="abbreviation" defaultValue={labAbbreviation} required />
                            </FormGroup>
                        </Row>
                        <Row>
                            <FormGroup className="w-100">
                                <Label for="labImage">Image</Label>
                                <input
                                    hidden
                                    type="hidden"
                                    name="imageId"
                                    value={selectedImage ? selectedImage.ImageId : ''}
                                />
                                <UncontrolledDropdown>
                                    <DropdownToggle className="w-100">
                                        {selectedImage ? selectedImage.Name : 'Images'}
                                    </DropdownToggle>
                                    <DropdownMenu className="justify-content-center w-100">
                                        {images.map(
                                            (image): ReactElement => (
                                                <DropdownItem
                                                    key={image.ImageId}
                                                    className="mx-auto w-100"
                                                    onClick={(): void => {
                                                        this.setState(
                                                            (prevState): LabEditFormState => ({
                                                                images: prevState.images,
                                                                selectedImage: image,
                                                            })
                                                        );
                                                    }}
                                                >
                                                    {image.Name}
                                                </DropdownItem>
                                            )
                                        )}
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </FormGroup>
                        </Row>
                        <Row>
                            <FormGroup className="w-100">
                                <Label for="labPassword">Default Password</Label>
                                <Input type="text" name="password" defaultValue={labPassword} required />
                            </FormGroup>
                        </Row>
                        <Row>
                            <Col>
                                <Row className="justify-content-around">
                                    <Button type="submit" color="primary">
                                        Submit
                                    </Button>
                                </Row>
                            </Col>
                            <Col>
                                <Row className="justify-content-around">
                                    <Button type="button" color="danger" onClick={onCancel}>
                                        Cancel
                                    </Button>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
        );
    }
}
