import React, { ReactElement } from 'react';
import { Jumbotron, Alert, Row } from 'reactstrap';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Breadcrumbs from 'react-router-dynamic-breadcrumbs';
import ChangePasswordForm from '../../components/Forms/ChangePasswordForm';
import { AuthContext, AuthContextValue } from '../../components/Auth/AuthContext';

interface ChangePasswordState {
    alertMessage: string;
    alertColor: string;
}

interface MyChangePasswordProps {
    firstLogin?: boolean;
}

type ChangePasswordProps = MyChangePasswordProps & RouteComponentProps;

class ChangePassword extends React.Component<ChangePasswordProps, ChangePasswordState> {
    public constructor(props: ChangePasswordProps) {
        super(props);
        this.state = {
            alertMessage: '',
            alertColor: '',
        };
    }

    private onSuccess = (event: any): void => {
        const { history } = this.props;
        const { state: historyState } = history.location;
        if (historyState && historyState.redirect) {
            history.push(historyState.redirect);
        } else {
            // if the AuthContext state was updated previously this will cause a 'Cannot update state of unmounted component' error.
            this.setState({ alertMessage: 'Your password has been successfully changed.', alertColor: 'success' });
        }
    };

    private onError = (error: any): void => {
        const { status } = error.response.data;
        const { summary } = error.response.data;
        const { details } = error.response.data;

        if (status === 500) {
            const alertMessage = 'Looks like something went from on our end. Consider contacting your supervisors.';
            this.setState({ alertColor: 'danger', alertMessage });
            return;
        }

        const alertMessage = `${summary}: ${details}`;
        this.setState({ alertMessage, alertColor: 'danger' });
    };

    public render(): ReactElement {
        const { firstLogin } = this.props;
        console.log('TCL: ChangePassword -> firstLogin', firstLogin);
        const { history } = this.props;
        const { state: historyState } = history.location;
        console.log('TCL: ChangePassword -> historyState', historyState);
        const { alertMessage, alertColor } = this.state;
        console.log('TCL: ChangePassword -> alertMessage, alertColor', alertMessage, alertColor);

        return (
            <>
                <Breadcrumbs
                    mappedRoutes={{
                        '/': 'Home',
                        '/labs': 'Labs',
                        '/images': 'Images',
                        '/change-password': 'Change Password',
                    }}
                />
                {(firstLogin || (historyState && historyState.firstLogin)) && (
                    <div>
                        <Row className="justify-content-center">
                            <h2>Welcome to the CloudLab!</h2>
                        </Row>
                        <Row className="justify-content-center">
                            <span>You must change your password before you can start.</span>
                        </Row>
                    </div>
                )}

                {alertMessage && <Alert color={alertColor}> {alertMessage} </Alert>}
                <Jumbotron className="mx-auto mt-5 w-50" style={{ background: 'white' }}>
                    <AuthContext.Consumer>
                        {(context: AuthContextValue): ReactElement => (
                            <ChangePasswordForm
                                onSubmit={(e: any): Promise<void> =>
                                    context
                                        .changePassword(e)
                                        .then(
                                            (event: any): void => {
                                                this.onSuccess(event);
                                            }
                                        )
                                        .catch(
                                            (error: any): void => {
                                                this.onError(error);
                                            }
                                        )
                                }
                            />
                        )}
                    </AuthContext.Consumer>
                </Jumbotron>
            </>
        );
    }
}

export default withRouter(ChangePassword);
