import React, { ReactElement } from 'react';
import { Row, Col, Alert, Input, Button, Label, Spinner } from 'reactstrap';
import { RouteComponentProps } from 'react-router';
import Create from '@material-ui/icons/Create.js';
import { Link } from 'react-router-dom';
import Breadcrumbs from 'react-router-dynamic-breadcrumbs';
import DataTable, { ColumnsJSON } from '../../components/Teacher/DataTables/DataTable';
import ExportButton from '../../components/Button/ExportButton';
import SimpleAddStudentButton from '../../components/Button/SimpleAddStudentButton';
import InstanceActionButton from '../../components/Button/InstanceActionButton';
import { Lab } from '../../helpers/requests/interfaces/labInterfaces';
import { getLab } from '../../helpers/requests/labRequests';
import { getLabGroup, deleteLabGroup, putLabGroup } from '../../helpers/requests/labGroupRequests';
import {
    getLabGroupStudents,
    postLabGroupStudents,
    patchLabGroupStudents,
    patchSingleStudent,
    resetStudentPassword,
    deleteStudent,
} from '../../helpers/requests/studentRequests';
import { LabGroupInformation } from '../../helpers/requests/interfaces/labGroupInterfaces';
import { Semester } from '../../helpers/requests/interfaces/semesterInterfaces';
import { getSemester } from '../../helpers/requests/semesterRequests';
import { StudentAndInstance } from '../../helpers/requests/interfaces/studentInterfaces';
import DeleteButton from '../../components/Button/DeleteButton';
import ConfirmModal from '../../components/Modal/ConfirmModal';

interface LabGroupStudentDisplayData {
    token: string;
    state: string;
    uuid: string;
}

export interface LabGroupProps {
    match: {
        params: {
            labUUID: string;
            semesterUUID: string;
            labgroupUUID: string;
        };
    };
}

interface LabGroupState {
    labInfo: Lab;
    semesterInfo: Semester;
    students: StudentAndInstance[];
    labGroupInformation: LabGroupInformation;
    displayData: LabGroupStudentDisplayData[];
    selected: string[];
    editName: boolean;
    inputName: string;
    showDeleteConfirmation: boolean;
    deleteUuid: string;
    alert: {
        showAlert: boolean;
        alertMessage: string;
        alertColor: string;
    };
    loading: boolean;
}

export default class LabGroup extends React.Component<LabGroupProps & RouteComponentProps, LabGroupState> {
    private columns: ColumnsJSON[] = [
        {
            dataField: 'token',
            text: 'Token',
            sort: true,
        },
        {
            dataField: 'state',
            text: 'VM State',
            sort: true,
        },
        {
            dataField: 'uuid',
            text: '',
            hidden: true,
        },
    ];

    public constructor(props: LabGroupProps & RouteComponentProps) {
        super(props);
        this.state = {
            labInfo: null,
            semesterInfo: null,
            labGroupInformation: null,
            students: [],
            displayData: [],
            selected: [],
            editName: false,
            inputName: '',
            showDeleteConfirmation: false,
            deleteUuid: '',
            alert: {
                showAlert: false,
                alertMessage: '',
                alertColor: '',
            },
            loading: false,
        };
    }

    public async componentWillMount(): Promise<void> {
        const { match } = this.props;
        const { labUUID, semesterUUID, labgroupUUID } = match.params;
        this.setState({ loading: true });

        await getLab(labUUID).then(
            (labInfo): void => {
                this.setState((prevState): LabGroupState => ({ ...prevState, labInfo }));
            }
        );
        await getLabGroup(labUUID, semesterUUID, labgroupUUID).then(
            (labGroupInformation): void => {
                this.setState((prevState): LabGroupState => ({ ...prevState, labGroupInformation }));
                this.setState({ inputName: labGroupInformation.name });
            }
        );
        await getSemester(labUUID, semesterUUID).then(
            (semesterInfo): void => {
                this.setState((prevState): LabGroupState => ({ ...prevState, semesterInfo }));
            }
        );

        const { labGroupInformation } = this.state;
        if (!labGroupInformation.toString().includes('404')) {
            this.fetchDataForDataTable();
        } else {
            const { history } = this.props;
            history.goBack();
        }
    }

    private onRowSelect = (selectedRows: string[]): void => {
        this.setState({ selected: selectedRows });
        const { selected, displayData } = this.state;
        console.log(selected);
        console.log(displayData.filter((student): boolean => selected.includes(student.uuid)));
    };

    private setAlert(alertMessage: string, alertColor: string, alertDurationOfAlert?: number): void {
        const alertDuration = alertDurationOfAlert || 10000;

        // TODO: Possibly handle alert timeouts with window.clearTimeout

        this.setState(
            {
                alert: {
                    alertColor,
                    alertMessage,
                    showAlert: true,
                },
            },
            (): void => {
                window.setTimeout((): void => {
                    this.setState({
                        alert: {
                            alertColor: '',
                            alertMessage: '',
                            showAlert: false,
                        },
                    });
                }, alertDuration);
            }
        );
    }

    private changeName = (e: { target: { value: string } }): void => {
        this.setState({ inputName: e.target.value });
    };

    public hideDeleteModal = (): void => {
        this.setState({ showDeleteConfirmation: false });
    };

    public deleteLabGroup = (): void => {
        const { match } = this.props;
        const { labUUID, semesterUUID, labgroupUUID } = match.params;
        deleteLabGroup(labUUID, semesterUUID, labgroupUUID).then(
            (): void => {
                console.log('Deleted labgroup successful');
                const { history } = this.props;
                history.push(`/labs/${labUUID}`);
            }
        );
    };

    private composeDisplayData(): void {
        const { students } = this.state;
        let atLeastOneStatePendingOrStopping = false;

        const displayData = students.map(
            (studentAndInstance: StudentAndInstance): LabGroupStudentDisplayData => {
                const stateVM =
                    (studentAndInstance.instanceInfo && studentAndInstance.instanceInfo.State) || 'Not yet created';
                if (stateVM !== 'stopped' || 'running' || 'Not yet created') {
                    atLeastOneStatePendingOrStopping = true;
                }
                const item = {
                    token: studentAndInstance.studentInfo.token,
                    state: stateVM,
                    uuid: studentAndInstance.studentInfo.uuid,
                };
                return item;
            }
        );
        this.setState({ displayData });

        // Fetch new information every 10 seconds if any instance is either currently not running, stopped or not yet created
        if (atLeastOneStatePendingOrStopping) {
            setTimeout((): void => {
                this.fetchDataForDataTable();
            }, 10000);
        }
    }

    private async fetchDataForDataTable(): Promise<void> {
        const { match } = this.props;
        const { labUUID, semesterUUID, labgroupUUID } = match.params;

        await getLabGroupStudents(labUUID, semesterUUID, labgroupUUID)
            .then(
                (students): void => {
                    this.setState((prevState): LabGroupState => ({ ...prevState, students }));
                }
            )
            .finally(
                (): void => {
                    this.setState({ loading: false });
                }
            );

        this.composeDisplayData();
    }

    private submitEdit(): void {
        const { inputName } = this.state;
        const name = inputName;
        const data = {
            name,
        };
        console.log('Updating LabGroup:', data);

        const { match } = this.props;
        const { labUUID, semesterUUID, labgroupUUID } = match.params;

        putLabGroup(labUUID, semesterUUID, labgroupUUID, name).then(
            (): void => {
                const { labGroupInformation } = this.state;
                const newLabGroupInfo = labGroupInformation;
                newLabGroupInfo.name = name;
                console.log('Update successful');
                this.setState({ editName: false, labGroupInformation: newLabGroupInfo });
            }
        );
    }

    private showErrorAlert(error: any, action: string): void {
        const { status } = error.response.data;
        const { summary } = error.response.data;
        const { details } = error.response.data;
        // TODO: Proper error handling
        this.setAlert(
            `Could not successfully ${action}. Failed with error: ${status}: ${summary} - ${details}`,
            'danger'
        );
    }

    private deleteStudentWithErrorHandling(uuid: string, action: string): void {
        deleteStudent(uuid)
            .then(
                (): void => {
                    this.setAlert(`Selected students did successfully ${action}.`, 'success');
                    this.fetchDataForDataTable();
                }
            )
            .catch(
                (error: any): Promise<void> => {
                    this.showErrorAlert(error, action);
                    return error;
                }
            );
    }

    private resetStudentPasswordWithErrorHandling(uuid: string, action: string): void {
        resetStudentPassword(uuid)
            .then(
                (): void => {
                    this.setAlert(`Selected students did successfully ${action}.`, 'success');
                }
            )
            .catch(
                (error: any): Promise<void> => {
                    this.showErrorAlert(error, action);
                    return error;
                }
            );
    }

    public render(): ReactElement {
        const {
            semesterInfo,
            labInfo,
            displayData,
            labGroupInformation,
            selected,
            alert,
            editName,
            showDeleteConfirmation,
            loading,
        } = this.state;
        const { match } = this.props;
        const { labUUID, semesterUUID, labgroupUUID } = match.params;
        return (
            <>
                {labInfo && semesterInfo && labGroupInformation && (
                    <Breadcrumbs
                        mappedRoutes={{
                            '/': 'Home',
                            '/labs': 'Labs',
                            '/images': 'Images',
                            '/labs/:labUUID': labInfo.name,
                            '/labs/:labUUID/edit': 'Edit',
                            '/labs/:labUUID/semesters': null,
                            '/labs/:labUUID/semesters/:sid': semesterInfo.name,
                            '/labs/:labUUID/semesters/:sid/labgroups': null,
                            '/labs/:labUUID/semesters/:sid/labgroups/:labgroupUUID': labGroupInformation.name,
                        }}
                    />
                )}
                <Row className="justify-content-center mb-5">
                    {labInfo && (
                        <h1>
                            <Link to={`/labs/${labInfo.uuid}`}>{labInfo.name}</Link>
                        </h1>
                    )}
                </Row>
                {semesterInfo && labGroupInformation && (
                    <Row className="my-3" style={{ fontSize: '1.4em' }}>
                        <Col xs={{ size: 3, offset: 2 }}>
                            <Row className="justify-content-start">
                                <span style={{ fontWeight: 'bold' }} className="mr-2">
                                    Semester:
                                </span>
                                <span>
                                    <Link to={`/labs/${labInfo.uuid}/semesters/${semesterInfo.uuid}`}>
                                        {semesterInfo.name}
                                    </Link>
                                </span>
                            </Row>
                        </Col>
                        {editName ? (
                            <Col xs={7}>
                                <Row className="justify-content-end">
                                    <Col xs={8} className="row" style={{ paddingLeft: '0px' }}>
                                        <span style={{ fontWeight: 'bold', padding: 0 }} className="mr-2 col-5">
                                            Lab group:
                                        </span>
                                        <Input
                                            onChange={this.changeName}
                                            type="text"
                                            name="name"
                                            defaultValue={labGroupInformation.name}
                                            className="col-6"
                                            style={{ paddingTop: '0' }}
                                        />
                                    </Col>
                                    <Col xs={2} style={{ marginTop: '-10px' }}>
                                        <Button
                                            type="button"
                                            color="primary"
                                            style={{ padding: '10px' }}
                                            onClick={(): void => {
                                                this.submitEdit();
                                            }}
                                        >
                                            Submit
                                        </Button>
                                    </Col>
                                    <Col xs={2} style={{ marginTop: '-10px' }}>
                                        <Button
                                            color="danger"
                                            style={{ padding: '10px' }}
                                            onClick={(): void => {
                                                this.setState({
                                                    editName: false,
                                                });
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        ) : (
                            <Col xs={7}>
                                <Row className="justify-content-end">
                                    <Col xs={8}>
                                        <span style={{ fontWeight: 'bold' }} className="mr-2">
                                            Lab group:
                                        </span>
                                        <span>{labGroupInformation.name}</span>
                                    </Col>
                                    <Col lg={2} style={{ marginTop: '-10px', paddingLeft: '0px', paddingRight: '0px' }}>
                                        <Button
                                            className="px-3 mx-2 p-2"
                                            color="primary"
                                            id="editButton"
                                            onClick={(): void => {
                                                this.setState({
                                                    editName: true,
                                                });
                                            }}
                                        >
                                            <Create />
                                            <span>Edit</span>
                                        </Button>
                                    </Col>
                                    <Col lg={2} style={{ marginTop: '-10px', paddingLeft: '0px', paddingRight: '0px' }}>
                                        <DeleteButton
                                            id={labgroupUUID}
                                            tooltipText="Delete lab group"
                                            className="ml-3"
                                            onClick={(): void => {
                                                this.setState({
                                                    showDeleteConfirmation: true,
                                                    deleteUuid: labgroupUUID,
                                                });
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        )}
                    </Row>
                )}
                <Row className="my-2">
                    {alert.showAlert && <Alert color={alert.alertColor}>{alert.alertMessage} </Alert>}
                    <Col sm={6} lg={8} className="justify-content-right">
                        {labInfo && labGroupInformation && (
                            <SimpleAddStudentButton
                                labGroup={labGroupInformation}
                                onSubmit={(event): Promise<void> => {
                                    event.preventDefault();

                                    const numberOfStudents = event.target.elements.numberOfStudents.value;
                                    const tags: string[] = [];

                                    return postLabGroupStudents(
                                        labUUID,
                                        semesterUUID,
                                        labgroupUUID,
                                        numberOfStudents,
                                        tags
                                    ).then(
                                        (): void => {
                                            this.fetchDataForDataTable();
                                        }
                                    );
                                }}
                                onClickHandler={(): void => {
                                    this.setAlert('Students are being added. This might take a moment.', 'info');
                                }}
                                successHandler={(): void => {
                                    this.setAlert('Students are added successfully.', 'success');
                                }}
                            />
                        )}
                    </Col>
                    <Col sm={3} lg={2} className="justify-content-center">
                        <InstanceActionButton
                            selected={displayData.filter((student): boolean => selected.includes(student.uuid))}
                            isDisabled={selected.length === 0}
                            onSubmit={(action): Promise<void> => {
                                this.setAlert(`Request to ${action} sent. Please wait.`, 'success');
                                if (
                                    action === 'terminate' ||
                                    action === 'start' ||
                                    action === 'stop' ||
                                    action === 'recreate'
                                ) {
                                    if (selected.length === displayData.length) {
                                        return patchLabGroupStudents(labUUID, semesterUUID, labgroupUUID, action)
                                            .then(
                                                (): void => {
                                                    this.setAlert(
                                                        `Selected instances did successfully ${action}.`,
                                                        'success'
                                                    );
                                                    this.fetchDataForDataTable();
                                                }
                                            )
                                            .catch(
                                                (error: any): Promise<void> => {
                                                    this.showErrorAlert(error, action);
                                                    return error;
                                                }
                                            );
                                    }
                                    selected.forEach(
                                        (value): void => {
                                            // TODO: error handling
                                            patchSingleStudent(value, action);
                                        }
                                    );
                                } else if (action === 'resetPassword') {
                                    selected.forEach(
                                        (value): void => {
                                            this.resetStudentPasswordWithErrorHandling(value, action);
                                        }
                                    );
                                } else if (action === 'delete') {
                                    selected.forEach(
                                        (value): void => {
                                            this.deleteStudentWithErrorHandling(value, action);
                                        }
                                    );
                                }
                                return null;
                            }}
                        />
                    </Col>
                    <Col sm={3} lg={2} className="float-right">
                        {labInfo && labGroupInformation && (
                            <ExportButton
                                tooltipText="Export all selected students"
                                data={displayData.filter((student): boolean => selected.includes(student.uuid))}
                                filename={`${labInfo.name}-${labGroupInformation.name}-Students.csv`}
                                isDisabled={selected.length === 0}
                            />
                        )}
                    </Col>
                </Row>
                {!loading ? (
                    <>
                        <Row className="mb-5">
                            <DataTable
                                displayData={displayData}
                                onRowSelect={this.onRowSelect}
                                columns={this.columns}
                            />
                        </Row>
                        <ConfirmModal
                            show={showDeleteConfirmation}
                            handleClose={this.hideDeleteModal}
                            action={this.deleteLabGroup}
                            type="delete"
                            itemsForDeletion={
                                labGroupInformation && displayData
                                    ? [
                                          { type: 'Labgroup', name: labGroupInformation.name },
                                          ...displayData.map(
                                              (item): { type: string; name: string } => ({
                                                  type: 'Student',
                                                  name: item.token,
                                              })
                                          ),
                                      ]
                                    : null
                            }
                        />
                    </>
                ) : (
                    <>
                        <Row className="justify-content-center">
                            <Label>
                                <h4>Loading...</h4>
                            </Label>
                        </Row>
                        <Row className="justify-content-center">
                            <Spinner animation="border" role="status" />
                        </Row>
                    </>
                )}
            </>
        );
    }
}
