import Axios from 'axios';
import { get as getCookie } from 'es-cookie';
import { StudentAndInstance, VMInstanceInfo } from './interfaces/studentInterfaces';

// Student Role

export function getStudentInfo(): Promise<VMInstanceInfo> {
    return Axios.get(`${process.env.API_URL}/student`, {
        responseType: 'json',
        headers: { Authorization: `Bearer ${getCookie('token')}` },
    }).then(
        (response: any): VMInstanceInfo => {
            const { data } = response;

            return data;
        }
    );
}

export function patchStudent(action: 'start' | 'stop' | 'recreate' | 'terminate'): Promise<void> {
    return Axios.patch(
        `${process.env.API_URL}/student`,
        { state: action },
        {
            responseType: 'json',
            headers: { Authorization: `Bearer ${getCookie('token')}` },
        }
    ).then();
}

export function patchSingleStudent(
    studentToken: string,
    action: 'start' | 'stop' | 'recreate' | 'terminate'
): Promise<void> {
    return Axios.patch(
        `${process.env.API_URL}/students/${studentToken}`,
        { state: action },
        {
            responseType: 'json',
            headers: { Authorization: `Bearer ${getCookie('token')}` },
        }
    ).then();
}

export function resetStudentPassword(studentToken: string): Promise<void> {
    return Axios.post(`${process.env.API_URL}/students/${studentToken}/password-reset`, undefined, {
        responseType: 'text',
        headers: { Authorization: `Bearer ${getCookie('token')}` },
    }).then();
}

export function deleteStudent(studentToken: string): Promise<void> {
    return Axios.delete(`${process.env.API_URL}/students/${studentToken}`, {
        headers: { Authorization: `Bearer ${getCookie('token')}` },
    })
        .then(
            (): void => {
                console.log('Successfully deleted Student');
            }
        )
        .catch(
            (error): void => {
                console.error(error);
            }
        );
}

// Teacher Role

export function getStudentsAndInstances(): Promise<StudentAndInstance[]> {
    return Axios.get(`${process.env.API_URL}/students`, {
        responseType: 'json',
        headers: { Authorization: `Bearer ${getCookie('token')}` },
    }).then(
        (response: any): StudentAndInstance[] => {
            const { data } = response;
            return data;
        }
    );
}

export function getLabGroupStudents(
    labUUID: string,
    semesterUUID: string,
    labGroupUUID: string
): Promise<StudentAndInstance[]> {
    return Axios.get(
        `${process.env.API_URL}/labs/${labUUID}/semesters/${semesterUUID}/labgroups/${labGroupUUID}/students`,
        {
            responseType: 'json',
            headers: { Authorization: `Bearer ${getCookie('token')}` },
        }
    ).then(
        (response: any): StudentAndInstance[] => {
            const { data } = response;
            return data;
        }
    );
}

export function postLabGroupStudents(
    labUUID: string,
    semesterUUID: string,
    labGroupUUID: string,
    numberOfStudents: string,
    tags: string[]
): Promise<void> {
    const url = `${process.env.API_URL}/labs/${labUUID}/semesters/${semesterUUID}/labgroups/${labGroupUUID}/students`;

    return Axios.post(
        url,
        { numberOfStudents, tags },
        {
            responseType: 'json',
            headers: { Authorization: `Bearer ${getCookie('token')}` },
        }
    )
        .then(
            (response: { data: string[] }): string[] => {
                const { data } = response;
                return data;
            }
        )
        .then(
            (studentTokens): void => {
                if (studentTokens.length !== parseInt(numberOfStudents, 10)) {
                    throw new Error('number of created students does not match');
                }
            }
        );
}

export function patchLabGroupStudents(
    labUUID: string,
    semesterUUID: string,
    labGroupUUID: string,
    action: 'start' | 'stop' | 'recreate' | 'terminate'
): Promise<void> {
    const url = `${process.env.API_URL}/labs/${labUUID}/semesters/${semesterUUID}/labgroups/${labGroupUUID}/students`;

    return Axios.patch(
        url,
        { state: action },
        {
            responseType: 'json',
            headers: { Authorization: `Bearer ${getCookie('token')}` },
        }
    ).then();
}

export function getSemesterStudents(labUUID: string, semesterUUID: string): Promise<StudentAndInstance[]> {
    return Axios.get(`${process.env.API_URL}/labs/${labUUID}/semesters/${semesterUUID}/students`, {
        responseType: 'json',
        headers: { Authorization: `Bearer ${getCookie('token')}` },
    }).then(
        (response: any): StudentAndInstance[] => {
            const { data } = response;
            return data;
        }
    );
}

export function postSemesterStudents(
    labUUID: string,
    semesterUUID: string,
    numberOfStudents: string,
    tags: string[]
): Promise<void> {
    const url = `${process.env.API_URL}/labs/${labUUID}/semesters/${semesterUUID}/students`;

    return Axios.post(
        url,
        { numberOfStudents, tags },
        {
            responseType: 'json',
            headers: { Authorization: `Bearer ${getCookie('token')}` },
        }
    )
        .then(
            (response: { data: string[] }): string[] => {
                const { data } = response;
                return data;
            }
        )
        .then(
            (studentTokens): void => {
                if (studentTokens.length !== parseInt(numberOfStudents, 10)) {
                    throw new Error('number of created students does not match');
                }
            }
        );
}

export function patchSemesterStudents(
    labUUID: string,
    semesterUUID: string,
    action: 'start' | 'stop' | 'recreate' | 'terminate'
): Promise<void> {
    const url = `${process.env.API_URL}/labs/${labUUID}/semesters/${semesterUUID}/students`;

    return Axios.patch(
        url,
        { state: action },
        {
            responseType: 'json',
            headers: { Authorization: `Bearer ${getCookie('token')}` },
        }
    ).then();
}
