import React, { Component, ReactElement } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Alert, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Spinner } from 'reactstrap';
import Info from '@material-ui/icons/Info.js';
import Breadcrumbs from 'react-router-dynamic-breadcrumbs';
import EditButton from '../../components/Button/EditButton';
import SemesterLabGroupTable from '../../components/Table/SemesterLabGroupTable/SemesterLabGroupTable';
import AddStudentButton from '../../components/Button/AddStudentButton';
import { Lab } from '../../helpers/requests/interfaces/labInterfaces';
import { getLab, deleteLab } from '../../helpers/requests/labRequests';
import { postLabGroupStudents, postSemesterStudents } from '../../helpers/requests/studentRequests';
import LabInfoTable from '../../components/Table/LabInfoTable';
import DeleteButton from '../../components/Button/DeleteButton';
import ConfirmModal from '../../components/Modal/ConfirmModal';

interface LabInfoState {
    lab?: Lab;
    showLabDetails: boolean;
    showDeleteConfirmation: boolean;
    semesterAdded: boolean;
    labGroupAdded: boolean;
    alert: {
        showAlert: boolean;
        alertMessage: string;
        alertColor: string;
    };
    loading: boolean;
}

class LabInfo extends Component<RouteComponentProps<{ labUUID: string }>, LabInfoState> {
    public constructor(props: RouteComponentProps<{ labUUID: string }>) {
        super(props);

        this.state = {
            showLabDetails: false,
            showDeleteConfirmation: false,
            semesterAdded: false,
            labGroupAdded: false,
            alert: {
                showAlert: false,
                alertMessage: '',
                alertColor: '',
            },
            loading: false,
        };
        this.toggleLabDetailsModal = this.toggleLabDetailsModal.bind(this);
        this.toggleDeleteConfirmModal = this.toggleDeleteConfirmModal.bind(this);
        this.toggleSemesterAdded = this.toggleSemesterAdded.bind(this);
        this.toggleLabGroupAdded = this.toggleLabGroupAdded.bind(this);
        this.showWaitAlert = this.showWaitAlert.bind(this);
        this.showSuccessAlert = this.showSuccessAlert.bind(this);
    }

    public async componentWillMount(): Promise<void> {
        const { match } = this.props;
        const { labUUID } = match.params;
        this.setState({ loading: true });

        await getLab(labUUID)
            .then(
                (lab): void => {
                    this.setState((prevState): LabInfoState => ({ ...prevState, lab }));
                }
            )
            .finally(
                (): void => {
                    this.setState({ loading: false });
                }
            );

        const { lab } = this.state;
        if (lab.toString().includes('404')) {
            const { history } = this.props;
            history.goBack();
        }
    }

    private setAlert(alertMessage: string, alertColor: string, alertDurationOfAlert?: number): void {
        const alertDuration = alertDurationOfAlert || 10000;

        // TODO: Possibly handle alert timeouts with window.clearTimeout

        this.setState(
            {
                alert: {
                    alertColor,
                    alertMessage,
                    showAlert: true,
                },
            },
            (): void => {
                window.setTimeout((): void => {
                    this.setState({
                        alert: {
                            alertColor: '',
                            alertMessage: '',
                            showAlert: false,
                        },
                    });
                }, alertDuration);
            }
        );
    }

    private deleteLab = (): void => {
        const { match } = this.props;
        const { labUUID } = match.params;
        deleteLab(labUUID).then(
            (): void => {
                const { history } = this.props;
                history.push('/labs/');
            }
        );
    };

    private toggleSemesterAdded(): void {
        this.setState((prevState): LabInfoState => ({ ...prevState, semesterAdded: !prevState.semesterAdded }));
    }

    private toggleLabGroupAdded(): void {
        this.setState((prevState): LabInfoState => ({ ...prevState, labGroupAdded: !prevState.labGroupAdded }));
    }

    private toggleLabDetailsModal(): void {
        this.setState((prevState): LabInfoState => ({ ...prevState, showLabDetails: !prevState.showLabDetails }));
    }

    private toggleDeleteConfirmModal(): void {
        this.setState(
            (prevState): LabInfoState => ({ ...prevState, showDeleteConfirmation: !prevState.showDeleteConfirmation })
        );
    }

    private showWaitAlert(): void {
        this.setAlert('Students are being added. This might take a moment.', 'info');
    }

    private showSuccessAlert(): void {
        this.setAlert('Students are added successfully.', 'success');
    }

    public render(): ReactElement {
        const {
            lab,
            showLabDetails,
            showDeleteConfirmation,
            semesterAdded,
            labGroupAdded,
            alert,
            loading,
        } = this.state;
        const { match } = this.props;
        const { labUUID } = match.params;

        return (
            <>
                {lab && (
                    <>
                        <Breadcrumbs
                            mappedRoutes={{
                                '/': 'Home',
                                '/labs': 'Labs',
                                '/images': 'Images',
                                '/labs/:labUUID': lab.name,
                                '/labs/:labUUID/edit': 'Edit',
                                '/labs/:labUUID/semesters': null,
                                '/labs/:labUUID/semesters/:sid': 'Semester Info',
                                '/labs/:labUUID/semesters/:sid/labgroups': null,
                                '/labs/:labUUID/semesters/:sid/labgroups/:labgroupUUID': 'Labgroup Info',
                            }}
                        />
                        <Row className="mb-4">
                            <Col sm={{ offset: 3, size: 6 }}>
                                <Row className="justify-content-center">
                                    <h1>{lab.name}</h1>
                                </Row>
                            </Col>
                            <Col sm={2.5}>
                                <Row className="justify-content-center">
                                    <Button
                                        className="p-2 px-3 mx-2"
                                        color="primary"
                                        onClick={this.toggleLabDetailsModal}
                                    >
                                        <Info /> Details
                                    </Button>
                                    <EditButton text="Edit" link={`/labs/${labUUID}/edit`} className="px-3 mr-0" />
                                </Row>
                                <Row className="justify-content-end">
                                    <DeleteButton
                                        onClick={this.toggleDeleteConfirmModal}
                                        className="px-3 mx-0"
                                        tooltipText="Delete lab"
                                        id={labUUID}
                                    />
                                </Row>
                            </Col>
                        </Row>
                        <Row className="my-3" style={{ fontSize: '1.4em' }}>
                            <Col xs={{ size: 3, offset: 2 }}>
                                <Row className="justify-content-start">
                                    <span style={{ fontWeight: 'bold' }} className="mr-2">
                                        Abbreviation:
                                    </span>
                                    <span>{lab.abbreviation}</span>
                                </Row>
                            </Col>
                            <Col xs={5}>
                                <Row className="justify-content-end">
                                    <span style={{ fontWeight: 'bold' }} className="mr-2">
                                        Default Password:
                                    </span>
                                    <span>{lab.password}</span>
                                </Row>
                            </Col>
                        </Row>
                        <Modal isOpen={showLabDetails} toggle={this.toggleLabDetailsModal} size="lg" scrollable>
                            <ModalHeader className="px-5" toggle={this.toggleLabDetailsModal}>
                                <span style={{ fontSize: '1.2em' }}>Lab Details</span>
                            </ModalHeader>
                            <ModalBody>
                                <Row className="justify-content-center">
                                    <Col xs={12} lg={10}>
                                        <LabInfoTable lab={lab} />
                                    </Col>
                                </Row>
                            </ModalBody>
                            <ModalFooter className="px-5">
                                <EditButton text="Edit" link={`/labs/${labUUID}/edit`} className="px-3 mx-2" />
                                <Button color="secondary" onClick={this.toggleLabDetailsModal}>
                                    Close
                                </Button>
                            </ModalFooter>
                        </Modal>
                        <Row className="pt-5 mb-2">
                            {alert.showAlert && <Alert color={alert.alertColor}>{alert.alertMessage} </Alert>}
                            <Col xs={{ size: 9, offset: 2 }} className="justify-content-right">
                                {lab && (
                                    <AddStudentButton
                                        lab={lab}
                                        onSubmit={(event): Promise<void> => {
                                            event.preventDefault();

                                            const labGroupUUID = event.target.elements.labGroupUUID.value;
                                            const semesterUUID = event.target.elements.semesterUUID.value;
                                            const numberOfStudents = event.target.elements.numberOfStudents.value;

                                            const tags: string[] = [];

                                            return labGroupUUID
                                                ? postLabGroupStudents(
                                                      labUUID,
                                                      semesterUUID,
                                                      labGroupUUID,
                                                      numberOfStudents,
                                                      tags
                                                  )
                                                : postSemesterStudents(labUUID, semesterUUID, numberOfStudents, tags);
                                        }}
                                        semesterAddedNotifier={semesterAdded}
                                        labGroupAddedNotifier={labGroupAdded}
                                        onClickHandler={this.showWaitAlert}
                                        successHandler={this.showSuccessAlert}
                                    />
                                )}
                            </Col>
                        </Row>
                        <>
                            <SemesterLabGroupTable
                                lab={lab}
                                addSemesterNotifier={this.toggleSemesterAdded}
                                addLabGroupNotifier={this.toggleLabGroupAdded}
                            />

                            <ConfirmModal
                                show={showDeleteConfirmation}
                                handleClose={this.toggleDeleteConfirmModal}
                                action={this.deleteLab}
                                type="delete"
                                itemsForDeletion={lab ? [{ type: 'Lab', name: lab.name }] : null}
                            />
                        </>
                    </>
                )}
                {loading && (
                    <>
                        <Row className="justify-content-center">
                            <Label>
                                <h4>Loading...</h4>
                            </Label>
                        </Row>
                        <Row className="justify-content-center">
                            <Spinner animation="border" role="status" />
                        </Row>
                    </>
                )}
            </>
        );
    }
}

export default withRouter(LabInfo);
