import Axios from 'axios';
import { get as getCookie } from 'es-cookie';
import { LabGroupInformation } from './interfaces/labGroupInterfaces';

export function getLabGroup(labUUID: string, semesterUUID: string, labGroupUUID: string): Promise<LabGroupInformation> {
    return Axios.get(`${process.env.API_URL}/labs/${labUUID}/semesters/${semesterUUID}/labgroups/${labGroupUUID}`, {
        responseType: 'json',
        headers: { Authorization: `Bearer ${getCookie('token')}` },
    })
        .then(
            (response: any): LabGroupInformation => {
                const { data } = response;
                return data;
            }
        )
        .catch(
            (error: any): any => {
                return error;
            }
        );
}

export function getLabGroups(labUUID: string, semesterUUID: string): Promise<LabGroupInformation[]> {
    return Axios.get(`${process.env.API_URL}/labs/${labUUID}/semesters/${semesterUUID}/labgroups`, {
        responseType: 'json',
        headers: { Authorization: `Bearer ${getCookie('token')}` },
    }).then(
        (response: any): LabGroupInformation[] => {
            const { data } = response;
            return data;
        }
    );
}

export function postLabGroup(
    labUUID: string,
    semesterUUID: string,
    labGroupName: string
): Promise<LabGroupInformation> {
    const url = `${process.env.API_URL}/labs/${labUUID}/semesters/${semesterUUID}/labgroups`;

    return Axios.post(
        url,
        { name: labGroupName },
        {
            responseType: 'json',
            headers: { Authorization: `Bearer ${getCookie('token')}` },
        }
    ).then(
        (response: { data: LabGroupInformation }): LabGroupInformation => {
            const { data } = response;
            return data;
        }
    );
}

export function putLabGroup(
    labUUID: string,
    semesterUUID: string,
    labGroupUUID: string,
    labGroupName: string
): Promise<void> {
    const url = `${process.env.API_URL}/labs/${labUUID}/semesters/${semesterUUID}/labgroups/${labGroupUUID}`;

    return Axios.put(
        url,
        { name: labGroupName },
        {
            responseType: 'json',
            headers: { Authorization: `Bearer ${getCookie('token')}` },
        }
    ).then();
}

export function deleteLabGroup(labUUID: string, semesterUUID: string, labGroupUUID: string): Promise<void> {
    const url = `${process.env.API_URL}/labs/${labUUID}/semesters/${semesterUUID}/labgroups/${labGroupUUID}`;

    return Axios.delete(url, {
        headers: { Authorization: `Bearer ${getCookie('token')}` },
    })
        .then(
            (): void => {
                console.log('Successfully deleted LabGroup');
            }
        )
        .catch(
            (error): void => {
                console.error(error);
            }
        );
}
