import React, { ReactElement } from 'react';
import { Row, Col, Label, Spinner } from 'reactstrap';
import Breadcrumbs from 'react-router-dynamic-breadcrumbs';
import DataTable, { ColumnsJSON } from '../../components/Teacher/DataTables/DataTable';
import { Image } from '../../helpers/requests/interfaces/imageInterfaces';
import { getImages } from '../../helpers/requests/imageRequests';

export interface ImageDisplayData {
    Architecture: string;
    CreationDate: string;
    ImageId: string;
    Platform: string;
    Name: string;
    uuid: string;
}

interface ImagesState {
    images: Image[];
    selected: string[];
    imageDisplayData: ImageDisplayData[];
    loading: boolean;
}

export default class Images extends React.Component<{}, ImagesState> {
    private columns: ColumnsJSON[] = [
        {
            dataField: 'Name',
            text: 'Name',
            sort: true,
        },
        {
            dataField: 'ImageId',
            text: 'Image Id',
            sort: true,
        },
        {
            dataField: 'Platform',
            text: 'Platform',
            sort: true,
        },
        {
            dataField: 'CreationDate',
            text: 'Creation Date',
            sort: true,
        },
    ];

    public constructor(props: {}) {
        super(props);

        this.state = {
            images: [],
            selected: [],
            imageDisplayData: [],
            loading: false,
        };
    }

    public async componentWillMount(): Promise<void> {
        this.setState({loading: true});
        await getImages()
            .then(
                (images: Image[]): void => {
                    this.setState({ images });
                }
            )
            .catch(
                (error: Error): void => {
                    console.log('Error when retreiving images!');
                    console.log(error);
                }
            )
            .finally(
                ():void=>{this.setState({loading: false});}
            );
        this.composeImageDataDisplay();
    }

    private onRowSelect = (selectedRows: string[]): void => {
        this.setState({ selected: selectedRows });
        const { selected } = this.state;
        console.log(selected);
    };

    private composeImageDataDisplay(): void {
        const { images } = this.state;

        const imageDisplayData = images.map(
            (image: Image): ImageDisplayData => {
                const item = {
                    Architecture: image.Architecture,
                    CreationDate: image.CreationDate,
                    ImageId: image.ImageId,
                    Platform: image.Platform,
                    Name: image.Name,
                    uuid: image.ImageId,
                };

                return item;
            }
        );
        this.setState({ imageDisplayData });
    }

    public render(): ReactElement {
        const { imageDisplayData, loading } = this.state;
        return (
            <>
                <Breadcrumbs
                    mappedRoutes={{
                        '/': 'Home',
                        '/labs': 'Labs',
                        '/images': 'Images',
                    }}
                />
                <Row className="justify-content-center">
                    <h2>Images</h2>
                </Row>
                {!loading ? (
                    <>
                        <Row className="my-2">
                            <Col sm={{ size: 3, offset: 9 }} lg={{ size: 2, offset: 10 }} className="float-right" />
                        </Row>
                        <Row className="p-2 mt-3">
                            <Col className="text-center">
                                <DataTable displayData={imageDisplayData} columns={this.columns} />
                            </Col>
                        </Row>
                    </>
                ) : (
                    <>
                        <Row className="justify-content-center">
                            <Label>
                                <h4>Loading Images...</h4>
                            </Label>
                        </Row>
                        <Row className="justify-content-center">
                            <Spinner animation="border" role="status" />
                        </Row>
                    </>
                )}
            </>
        );
    }
}
