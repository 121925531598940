import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { AuthProvider } from './components/Auth/AuthContext';
import App from './components/App/App';

import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/styles/material-dashboard.scss';
// import './assets/styles/hamburger/hamburgers.min.css';

import './assets/styles/index.scss';

const app = document.getElementById('app');

ReactDOM.render(
    <AuthProvider>
        <App />
    </AuthProvider>,
    app
);
