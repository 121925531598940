import React, { ReactElement, Component } from 'react';
import { Button } from 'reactstrap';
import { patchStudent } from '../../helpers/requests/studentRequests';
import ConfirmVmTerminateModal from '../Modal/ConfirmVmTerminateModal';

export interface VMInstanceActionButtonProps {
    buttonText: string;
    action: 'start' | 'stop' | 'recreate' | 'terminate';
    className?: string;
    callback?: () => void;
    id?: string;
}

interface VMInstanceActionButtonState {
    showConfirmationModal: boolean;
    disabledStatus: boolean;
}

export class VMInstanceActionButton extends Component<VMInstanceActionButtonProps, VMInstanceActionButtonState> {
    public constructor(props: VMInstanceActionButtonProps) {
        super(props);

        this.state = { showConfirmationModal: false, disabledStatus: false };
    }

    private hideConfirmationModal = (): void => {
        this.setState({ showConfirmationModal: false });
    };

    private doAction = (): void => {
        this.setState({ disabledStatus: true });
        const { action, callback } = this.props;
        patchStudent(action)
            .then(
                (): void => {
                    if (callback !== undefined) callback();
                }
            )
            .catch(
                (error: any): void => {
                    console.log(error);
                }
            );
    };

    public render(): ReactElement {
        const { buttonText, action, className, id } = this.props;
        const { showConfirmationModal, disabledStatus } = this.state;
        return (
            <>
                <Button
                    color="primary"
                    id={id}
                    className={className}
                    onClick={(): void => {
                        if (action !== 'recreate' && action !== 'terminate') {
                            this.doAction();
                        } else {
                            this.setState({ showConfirmationModal: true });
                        }
                    }}
                    disabled={disabledStatus}
                >
                    {buttonText}
                </Button>
                {(action === 'recreate' || action === 'terminate') && (
                    <ConfirmVmTerminateModal
                        show={showConfirmationModal}
                        handleClose={this.hideConfirmationModal}
                        deleteAction={this.doAction}
                        type={action}
                        disabled={disabledStatus}
                    />
                )}
            </>
        );
    }
}
