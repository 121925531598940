import React, { ReactElement, useState, useEffect } from 'react';
import { Row, Col, Table, Label, Spinner } from 'reactstrap';
import { truncate } from 'fs';
import SemesterRow from './SemesterRow';
import AddSemesterButton from './AddSemesterButton';
import { Semester } from '../../../helpers/requests/interfaces/semesterInterfaces';
import { Lab } from '../../../helpers/requests/interfaces/labInterfaces';
import { getSemesters, postSemester } from '../../../helpers/requests/semesterRequests';

export default function SemesterLabGroupTable({
    lab,
    className,
    addSemesterNotifier,
    addLabGroupNotifier,
}: {
    lab: Lab;
    className?: string;
    addSemesterNotifier: () => void;
    addLabGroupNotifier: () => void;
}): ReactElement {
    const [semesters, setSemesters] = useState<Semester[]>([]);
    const [loading = true, setLoading] = useState<boolean>();
    useEffect((): void => {
        getSemesters(lab.uuid).then(
            (data): void => {
                setSemesters(data);
                setLoading(false);
            }
        );
    }, []);

    return (
        <>
            {!loading ? (
                <Row className={className}>
                    <Col xs={{ size: 10, offset: 1 }}>
                        <Table>
                            <thead>
                                <tr className="d-flex">
                                    <th
                                        className="col-3 text-center"
                                        style={{
                                            borderRight: '2px solid rgba(0, 0, 0, 0.06)',
                                            fontSize: '1.3em',
                                        }}
                                    >
                                        Semester
                                    </th>
                                    <th className="col text-center" style={{ fontSize: '1.3em' }}>
                                        Lab groups
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {semesters &&
                                    semesters.map(
                                        (semester): ReactElement => (
                                            <SemesterRow
                                                semester={semester}
                                                key={semester.uuid}
                                                addLabGroupNotifier={addLabGroupNotifier}
                                            />
                                        )
                                    )}
                                <tr className="d-flex">
                                    <td
                                        className="col-3 text-center"
                                        style={{ borderRight: '2px solid rgba(0, 0, 0, 0.06)' }}
                                    >
                                        <AddSemesterButton
                                            submit={(event: any): Promise<void> => {
                                                event.preventDefault();

                                                const semesterName: string = event.target.elements.semesterName.value;

                                                // Datetime conversion german string format to iso string at 0 o'clock
                                                const startDateValue: string = event.target.elements.startDate.value;
                                                const startDateArray = startDateValue.split('/');
                                                const startDateGMT = new Date(
                                                    2000 + parseInt(startDateArray[2], 10),
                                                    parseInt(startDateArray[1], 10) - 1,
                                                    parseInt(startDateArray[0], 10),
                                                    0,
                                                    0,
                                                    0,
                                                    0
                                                );
                                                const startDate = new Date(
                                                    startDateGMT.valueOf() + 2 * 60 * 60 * 1000
                                                ).toISOString();

                                                // Datetime conversion german string format to iso string at 23:59 o'clock
                                                const endDateValue: string = event.target.elements.endDate.value;
                                                const endDateArray = endDateValue.split('/');
                                                const endDateGMT = new Date(
                                                    2000 + parseInt(endDateArray[2], 10),
                                                    parseInt(endDateArray[1], 10) - 1,
                                                    parseInt(endDateArray[0], 10),
                                                    23,
                                                    59,
                                                    59,
                                                    999
                                                );
                                                const endDate = new Date(
                                                    endDateGMT.valueOf() + 2 * 60 * 60 * 1000
                                                ).toISOString();

                                                return postSemester(lab.uuid, semesterName, startDate, endDate).then(
                                                    (data): void => {
                                                        setSemesters([...semesters, data]);
                                                    }
                                                );
                                            }}
                                            handler={addSemesterNotifier}
                                        />
                                    </td>
                                    <td className="col text-left" />
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            ) : (
                <>
                    <Row className="justify-content-center">
                        <Label>
                            <h4>Loading...</h4>
                        </Label>
                    </Row>
                    <Row className="justify-content-center">
                        <Spinner animation="border" role="status" />
                    </Row>
                </>
            )}
        </>
    );
}
