import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Jumbotron, Alert } from 'reactstrap';
import Breadcrumbs from 'react-router-dynamic-breadcrumbs';
import FeedbackForm from '../../components/Forms/FeedbackForm';
import postFeedback from '../../helpers/requests/feedbackRequests';

interface FeedbackState {
    alert: boolean;
    alertMessage: string;
    success: boolean;
}
interface FeedbackProps extends RouteComponentProps {
    whatAreProps: string;
}

class Feedback extends React.Component<FeedbackProps, FeedbackState> {
    public constructor(props: FeedbackProps) {
        super(props);
        this.state = {
            alert: false,
            alertMessage: '',
            success: false,
        };
    }

    private onSubmit = (event: any): void => {
        event.preventDefault();

        const reason = event.target.elements.reason.value;
        const details = event.target.elements.details.value;
        let otherReason = '';
        if (reason === 'OTHER') {
            otherReason = event.target.elements.otherReason.value;
        }

        postFeedback(reason, details, otherReason)
            .then(
                (): void => {
                    const { history } = this.props;
                    this.setState({ alert: false, success: true, alertMessage: '' });
                    setTimeout((): void => history.push('/'), 3000);
                }
            )
            .catch(
                (error: any): void => {
                    console.log(error);
                    this.setState({
                        alert: true,
                        success: false,
                        alertMessage: `${error}`,
                    });
                }
            );
    };

    public render(): React.ReactElement {
        const { alert, alertMessage, success } = this.state;
        return (
            <>
                <Breadcrumbs
                    mappedRoutes={{
                        '/': 'Home',
                        '/labs': 'Labs',
                        '/images': 'Images',
                        '/feedback': 'Feedback',
                    }}
                />
                {alert && <Alert color="danger">{alertMessage}</Alert>}
                {success && (
                    <Alert color="success">
                        Feedback has been successfully delivered. Thank you for your contribution to CloudLab. You'll be
                        redirected shortly.
                    </Alert>
                )}

                <Jumbotron className="mx-auto mt-5 w-50" style={{ background: 'white' }}>
                    <>
                        <h3>Sending Feedback</h3>
                        <FeedbackForm onSubmit={this.onSubmit} />
                    </>
                </Jumbotron>
            </>
        );
    }
}

export default withRouter(Feedback);
