import React, { ReactElement } from 'react';
import { Button } from 'reactstrap';
import Add from '@material-ui/icons/Add';

export default function CreateButton({ onClick }: { onClick: () => void }): ReactElement {
    return (
        <Button className="px-3" color="primary" onClick={onClick}>
            <Add />
            <span className="mx-3 h5 align-middle">Create</span>
        </Button>
    );
}
