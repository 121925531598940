import Axios from 'axios';
import { get as getCookie } from 'es-cookie';
import { Semester } from './interfaces/semesterInterfaces';

export function getSemester(labUUID: string, semesterUUID: string): Promise<Semester> {
    return Axios.get(`${process.env.API_URL}/labs/${labUUID}/semesters/${semesterUUID}`, {
        responseType: 'json',
        headers: { Authorization: `Bearer ${getCookie('token')}` },
    })
        .then(
            (response: any): Semester => {
                const { data } = response;
                return data;
            }
        )
        .catch(
            (error: any): any => {
                return error;
            }
        );
}

export function getSemesters(labUUID: string): Promise<Semester[]> {
    return Axios.get(`${process.env.API_URL}/labs/${labUUID}/semesters/`, {
        responseType: 'json',
        headers: { Authorization: `Bearer ${getCookie('token')}` },
    })
        .then(
            (response: any): Semester[] => {
                const { data } = response;
                return data;
            }
        )
        .catch(
            (error: any): any => {
                return error;
            }
        );
}

export function postSemester(
    labUUID: string,
    semesterName: string,
    startDate: string,
    endDate: string
): Promise<Semester> {
    const url = `${process.env.API_URL}/labs/${labUUID}/semesters`;

    return Axios.post(
        url,
        { name: semesterName, startDate, endDate },
        {
            responseType: 'json',
            headers: { Authorization: `Bearer ${getCookie('token')}` },
        }
    ).then(
        (response: { data: Semester }): Semester => {
            const { data } = response;
            return data;
        }
    );
}

export function putSemester(
    labUUID: string,
    semesterUUID: string,
    semesterName: string,
    startDate: Date,
    endDate: Date
): Promise<void> {
    const url = `${process.env.API_URL}/labs/${labUUID}/semesters/${semesterUUID}`;

    return Axios.put(
        url,
        { name: semesterName, startDate, endDate },
        {
            responseType: 'json',
            headers: { Authorization: `Bearer ${getCookie('token')}` },
        }
    ).then();
}

export function deleteSemester(labUUID: string, semesterUUID: string): Promise<void> {
    const url = `${process.env.API_URL}/labs/${labUUID}/semesters/${semesterUUID}`;

    return Axios.delete(url, {
        headers: { Authorization: `Bearer ${getCookie('token')}` },
    })
        .then(
            (): void => {
                console.log('Successfully deleted Semester');
            }
        )
        .catch(
            (error): void => {
                console.error(error);
            }
        );
}
