import React, { ReactElement } from 'react';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { LabGroupInformation } from '../../../helpers/requests/interfaces/labGroupInterfaces';

export default function LabGroupInfoButton({
    labgroup,
    classname,
}: {
    labgroup: LabGroupInformation;
    classname?: string;
}): ReactElement {
    return (
        <Button
            className={classname}
            tag={Link}
            to={`/labs/${labgroup.labUUID}/semesters/${labgroup.semesterUUID}/labgroups/${labgroup.uuid}`}
        >
            {labgroup.name}
        </Button>
    );
}
