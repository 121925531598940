import React, { ReactElement } from 'react';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { saveAs } from 'file-saver';
import CloudDownload from '@material-ui/icons/CloudDownload';

export default function ExportButton({
    data,
    filename,
    tooltipText,
    isDisabled,
}: {
    data: any[];
    filename: string;
    tooltipText?: string;
    isDisabled?: boolean;
}): ReactElement {
    return (
        <>
            <Button
                onClick={(): void => {
                    console.log('data: ', data);

                    let columns: string[];
                    try {
                        columns = Object.keys(data[0]);
                    } catch {
                        columns = ['nothing was selected'];
                    }

                    const csvData = new Blob([
                        `${columns.join(';')}\n`,
                        ...data.map(
                            (row: any): string =>
                                `${Object.entries(row)
                                    .filter((kv): boolean => columns.includes(kv[0]))
                                    .map((kv): string => `${kv[1]}`)
                                    .join(';')}\n`
                        ),
                    ]);

                    saveAs(csvData, filename.endsWith('.csv') ? filename : `${filename}.csv`);
                }}
                className={isDisabled ? 'px-3 btn-disabled' : 'px-3'}
                color="primary"
                id="exportButton"
                disabled={isDisabled}
            >
                <CloudDownload />
                <span className="mx-3 h5 align-middle">Export</span>
            </Button>
            {tooltipText && tooltipText && (
                <UncontrolledTooltip placement="top" target="exportButton">
                    {tooltipText}
                </UncontrolledTooltip>
            )}
        </>
    );
}
