import React, { ReactElement } from 'react';
import { Button, Form, FormGroup, Label, Input, Jumbotron, Row, Col, Alert, FormText, Spinner } from 'reactstrap';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { AuthContext, AuthContextValue } from '../../components/Auth/AuthContext';

interface LoginState {
    alertMessage: string;
    alert: boolean;
    password: string;
    username: string;
    passwordFieldErrorMessage: string;
    isLoading: boolean;
}

interface MyLoginProps {
    redirect?: string;
}

type LoginProps = MyLoginProps & RouteComponentProps;

class Login extends React.Component<LoginProps, LoginState> {
    public constructor(props: LoginProps) {
        super(props);

        this.state = {
            alertMessage: '',
            alert: false,
            password: '',
            username: '',
            passwordFieldErrorMessage: '',
            isLoading: false,
        };
    }

    private onChange = (event: any): void => {
        const input: string = event.target.value.trim();
        const field: string = event.target.name;
        this.setState(
            (prevstate: LoginState): LoginState => ({
                ...prevstate,
                [field]: input,
            })
        );
    };

    private onError(error: any): void {
        console.log(error.response.data);
        if (error.response.data.status === 400) {
            const alertMessage = `${error.response.data.summary}: ${error.response.data.details}`;
            this.setState({ alert: true, alertMessage });
        } else if (error.response.data.status === 500) {
            const alertMessage = 'Looks like something went from on our end. Consider contacting your supervisors.';
            this.setState({ alert: true, alertMessage });
        }
        setTimeout((): void => {
            this.setState({ alert: false });
        }, 5000);
    }

    private onBlur = (event: any): any => {
        const password: string = event.target.value;
        if (password.length < 6) {
            this.setState({
                passwordFieldErrorMessage: 'Passwords are at least 6 characters long.',
            });
        }
    };

    public render(): ReactElement {
        const { history, redirect } = this.props;
        const { alert, alertMessage, username, password, passwordFieldErrorMessage, isLoading } = this.state;
        return (
            <>
                {alert && <Alert color="danger">{alertMessage}</Alert>}
                <Jumbotron className="mx-auto mt-5 w-50" style={{ background: 'white' }}>
                    <AuthContext.Consumer>
                        {(context: AuthContextValue): ReactElement => (
                            <Form
                                onSubmit={(e: any): Promise<void> =>
                                    context.login(e, history, redirect).catch(
                                        (error: any): void => {
                                            this.onError(error);
                                            this.setState({ isLoading: false });
                                        }
                                    )
                                }
                                onSubmitCapture={(): void => this.setState({ isLoading: true })}
                            >
                                {!isLoading ? (
                                    <>
                                        <Row className="justify-content-center">
                                            <Col sm={10} md={8}>
                                                <FormGroup className="text-center">
                                                    <Label for="username">
                                                        <h4>Username</h4>
                                                    </Label>
                                                    <Input
                                                        type="text"
                                                        name="username"
                                                        id="emailInputField"
                                                        placeholder="username"
                                                        value={username}
                                                        onChange={this.onChange}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row className="justify-content-center">
                                            <Col sm={10} md={8}>
                                                <FormGroup className="mt-4 text-center">
                                                    <Label for="password">
                                                        <h4>Password</h4>
                                                    </Label>
                                                    <Input
                                                        type="password"
                                                        name="password"
                                                        id="passwordInputField"
                                                        placeholder="password"
                                                        value={password}
                                                        onBlur={this.onBlur}
                                                        onChange={this.onChange}
                                                    />
                                                    {passwordFieldErrorMessage.length > 0 && (
                                                        <FormText color="danger">{passwordFieldErrorMessage}</FormText>
                                                    )}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Col sm={{ size: 4, offset: 3 }} lg={{ size: 4, offset: 4 }}>
                                            <Button
                                                color="primary"
                                                size="lg"
                                                className="mt-5"
                                                disabled={!username || !password || password.length < 6}
                                            >
                                                Login
                                            </Button>
                                        </Col>
                                    </>
                                ) : (
                                    <>
                                        <Row className="justify-content-center">
                                            <Label>
                                                <h4>Loading...</h4>
                                            </Label>
                                        </Row>
                                        <Row className="justify-content-center">
                                            <Spinner animation="border" role="status" />
                                        </Row>
                                    </>
                                )}
                            </Form>
                        )}
                    </AuthContext.Consumer>
                </Jumbotron>
            </>
        );
    }
}

export default withRouter(Login);
