import React, { ReactElement } from 'react';
import {
    Row,
    Col,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    FormText,
} from 'reactstrap';
import { getImages } from '../../helpers/requests/imageRequests';
import { Image } from '../../helpers/requests/interfaces/imageInterfaces';

interface LabCreationFormProps {
    onSubmit: (event: any) => void;
    onCancel: () => void;
}

interface LabCreationFormState {
    images: Image[];
    selectedImage?: Image;
    name: string;
    abbreviation: string;
    password: string;
    submitClicked: boolean;
}

export default class LabCreationForm extends React.Component<LabCreationFormProps, LabCreationFormState> {
    public constructor(props: LabCreationFormProps) {
        super(props);

        this.state = {
            images: [],
            name: '',
            abbreviation: '',
            password: '',
            submitClicked: false,
        };
    }

    public componentWillMount(): void {
        getImages()
            .then(
                (images): void => {
                    this.setState({ images });
                }
            )
            .catch(
                (error: Error): void => {
                    console.log('Error when retreiving images!');
                    console.log(error);
                }
            );
    }

    private onChange = (event: any): void => {
        const input: string = event.target.value;
        const field: string = event.target.name;
        this.setState(
            (prevstate: LabCreationFormState): LabCreationFormState => ({
                ...prevstate,
                [field]: input,
            })
        );
    };

    private submitIsClicked = (): void => {
        this.setState(
            (prevState): LabCreationFormState => ({
                images: prevState.images,
                selectedImage: prevState.selectedImage,
                name: prevState.name,
                abbreviation: prevState.abbreviation,
                password: prevState.password,
                submitClicked: true,
            })
        );
    };

    public render(): ReactElement {
        const { onSubmit, onCancel } = this.props;
        const { images } = this.state;
        const { selectedImage, name, abbreviation, password, submitClicked } = this.state;

        return (
            <Form onSubmit={onSubmit}>
                <Row className="justify-content-center px-4">
                    <Col md={3} className="mr-3">
                        <FormGroup>
                            <Label for="labName" className="w-100 text-center">
                                <span>Name</span>
                            </Label>
                            <Input type="text" name="name" className="pl-3" onChange={this.onChange} required />
                        </FormGroup>
                    </Col>
                    <Col md={2} className="mx-3">
                        <FormGroup>
                            <Label for="labAbbreviation" className="w-100 text-center">
                                <span>Abbreviation</span>
                            </Label>
                            <Input type="text" name="abbreviation" className="pl-3" onChange={this.onChange} required />
                        </FormGroup>
                    </Col>
                    <Col md={2} className="mx-3">
                        <FormGroup>
                            <Label for="labPassword" className="w-100 text-center">
                                <span>Default Password</span>
                            </Label>
                            <Input type="text" name="password" className="pl-3" onChange={this.onChange} required />
                            <FormText color={password.length > 0 && password.length < 6 ? 'danger' : 'muted'}>
                                Minimum length of 6 characters.
                            </FormText>
                        </FormGroup>
                    </Col>
                    <Col md={2} className="mx-3 px-1">
                        <FormGroup>
                            <Label for="labImage" className="w-100 text-center">
                                <span>Image</span>
                            </Label>
                            <input
                                hidden
                                type="hidden"
                                name="imageId"
                                value={selectedImage ? selectedImage.ImageId : ''}
                                required
                            />
                            <UncontrolledDropdown>
                                <DropdownToggle className="w-100">
                                    {selectedImage ? selectedImage.Name : 'Images'}
                                </DropdownToggle>
                                <DropdownMenu className="justify-content-center w-100">
                                    {images.map(
                                        (image): ReactElement => (
                                            <DropdownItem
                                                key={image.ImageId}
                                                className="mx-auto w-100"
                                                onClick={(): void => {
                                                    this.setState(
                                                        (prevState): LabCreationFormState => ({
                                                            images: prevState.images,
                                                            selectedImage: image,
                                                            name: prevState.name,
                                                            abbreviation: prevState.abbreviation,
                                                            password: prevState.password,
                                                            submitClicked: prevState.submitClicked,
                                                        })
                                                    );
                                                }}
                                            >
                                                {image.Name}
                                            </DropdownItem>
                                        )
                                    )}
                                </DropdownMenu>
                            </UncontrolledDropdown>
                            <FormText color="danger" hidden={!(selectedImage == null && submitClicked)}>
                                Please choose a picture.
                            </FormText>
                        </FormGroup>
                    </Col>
                    <Col>
                        <Row className="text-right">
                            <Col>
                                <Button type="submit" color="primary" onClick={this.submitIsClicked}>
                                    Create
                                </Button>
                            </Col>
                            <Col>
                                <Button type="button" color="danger" onClick={onCancel}>
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
        );
    }
}
