import React, { ReactElement } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export default function ConfirmVmTerminateModal({
    show,
    handleClose,
    deleteAction,
    type,
    itemsForTermination,
    disabled
}: {
    show: boolean;
    handleClose: () => void;
    deleteAction: () => void;
    disabled: boolean;
    type: 'recreate' | 'terminate';
    itemsForTermination?: { type?: string; name: string }[];
}): ReactElement {
    return (
        <Modal isOpen={show} size="md" toggle={handleClose} scrollable>
            <ModalHeader className="px-5" toggle={handleClose}>
                <span style={{ fontSize: '1.2em' }}>
                    {type === 'terminate' && 'Delete VM'}
                    {type === 'recreate' && 'Recreate VM'}
                </span>
            </ModalHeader>
            <ModalBody>
                <>
                    <span style={{ fontSize: '1em' }}>
                        {type === 'terminate' &&
                            (itemsForTermination
                                ? 'Are you certain you want to delete the following items?'
                                : 'Are you certain you want to delete?')}
                        {type === 'recreate' &&
                            (itemsForTermination
                                ? 'Are you certain you want to recreate the following items?'
                                : 'Are you certain you want to recreate?')}
                    </span>

                    {itemsForTermination && (
                        <ul>
                            {itemsForTermination.map(
                                (item): ReactElement => (
                                    <li key={item.name.toString()}>
                                        {item.type && `${item.type}: `}
                                        {item.name}
                                    </li>
                                )
                            )}
                        </ul>
                    )}
                </>
            </ModalBody>
            <ModalFooter className="px-5">
                <Button color="danger" onClick={deleteAction} disabled={disabled}
>
                    {type === 'terminate' && 'Delete'}
                    {type === 'recreate' && 'Recreate'}
                </Button>
                <Button color="secondary" onClick={handleClose}>
                    Close
                </Button>
            </ModalFooter>
        </Modal>
    );
}
