import React, { useState, useEffect, ReactElement } from 'react';
import { Col, FormGroup, Label, Input, Button, Row, Form } from 'reactstrap';
import { LabGroupInformation } from '../../helpers/requests/interfaces/labGroupInterfaces';
import { Semester } from '../../helpers/requests/interfaces/semesterInterfaces';

interface PropsGivenLabGroup {
    className?: string;
    onSubmit: (event: any) => Promise<void>;
    labGroup: LabGroupInformation;
    onClickHandler: () => void;
    successHandler: () => void;
}

export default function SimpleAddStudentButton(props: PropsGivenLabGroup): ReactElement {
    const [inCreate, setInCreate] = useState(false);
    const [addButtonClicked, setAddButtonClicked] = useState(false);
    const [nrOfStudents, setNrOfStudents] = useState<number>(0);
    const [formIsValid, setFormIsValid] = useState(false);

    const { onSubmit, labGroup, className, onClickHandler, successHandler } = props;

    useEffect((): void => {
        if (nrOfStudents > 0) {
            setFormIsValid(true);
        } else {
            setFormIsValid(false);
        }
    }, [nrOfStudents]);

    return (
        <>
            <Row className="float-right h-100">
                {inCreate ? (
                    <Form
                        onSubmit={(event: any): void => {
                            setAddButtonClicked(true);
                            onSubmit(event).then(
                                (): void => {
                                    setInCreate(false);
                                    setAddButtonClicked(false);
                                    setFormIsValid(false);
                                    successHandler();
                                }
                            );
                        }}
                    >
                        <>
                            <input hidden style={{ display: 'none' }} name="labUUID" defaultValue={labGroup.labUUID} />
                            <input
                                hidden
                                style={{ display: 'none' }}
                                name="semesterUUID"
                                defaultValue={labGroup.semesterUUID}
                            />
                            <input
                                hidden
                                style={{ display: 'none' }}
                                name="labGroupUUID"
                                defaultValue={labGroup.uuid}
                            />
                        </>

                        <Row className="justify-content-center">
                            <Col xs={{ size: 3, offset: 2 }}>
                                <FormGroup>
                                    <Label for="numberOfStudents" className="text-center w-100">
                                        Number of students
                                    </Label>
                                    <Row className="btn btn-secondary" style={{ padding: '2px' }}>
                                        <Input
                                            className="text-center"
                                            name="numberOfStudents"
                                            type="number"
                                            min="1"
                                            onChange={(e): void => {
                                                setNrOfStudents(e.target.valueAsNumber);
                                            }}
                                        />
                                    </Row>
                                </FormGroup>
                            </Col>
                            <Col xs={{ size: 4, offset: 1 }}>
                                <Row className="h-100 align-items-center">
                                    <Col>
                                        {formIsValid ? (
                                            <Button
                                                className="my-auto"
                                                type="submit"
                                                color="primary"
                                                onClick={(): void => {
                                                    onClickHandler();
                                                }}
                                                disabled={addButtonClicked}
                                            >
                                                Add
                                            </Button>
                                        ) : (
                                            <Button className="my-auto" type="submit" color="primary" disabled>
                                                Add
                                            </Button>
                                        )}
                                    </Col>
                                    <Col>
                                        <Button
                                            color="danger"
                                            className="px-3"
                                            onClick={(): void => {
                                                setInCreate(false);
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                ) : (
                    <Button
                        color="primary"
                        className="my-auto"
                        onClick={(): void => {
                            setInCreate(true);
                        }}
                    >
                        Add Student
                    </Button>
                )}
            </Row>
        </>
    );
}
