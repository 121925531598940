import React, { ReactElement } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export default function ConfirmModal({
    show,
    handleClose,
    action,
    type,
    itemsForDeletion,
}: {
    show: boolean;
    handleClose: () => void;
    action: () => void;
    type: 'delete' | 'resetPassword';
    itemsForDeletion?: { type?: string; name: string }[];
}): ReactElement {
    return (
        <Modal isOpen={show} size="md" toggle={handleClose} scrollable>
            <ModalHeader className="px-5" toggle={handleClose}>
                <span style={{ fontSize: '1.2em' }}>
                    {type === 'delete' && 'Delete'}
                    {type === 'resetPassword' && 'Reset Password'}
                </span>
            </ModalHeader>
            <ModalBody>
                <>
                    <span style={{ fontSize: '1em' }}>
                        {type === 'delete' &&
                            (itemsForDeletion
                                ? 'Are you sure you want to delete the listed and all sub-items?'
                                : 'Are you sure you want to delete?')}
                        {type === 'resetPassword' &&
                            (itemsForDeletion
                                ? 'Are you sure you want to reset these passwords?'
                                : 'Are you sure you want to reset this password?')}
                    </span>

                    {itemsForDeletion && (
                        <ul>
                            {itemsForDeletion.map(
                                (item): ReactElement => (
                                    <li key={item.name.toString()}>
                                        {item.type && `${item.type}: `}
                                        {item.name}
                                    </li>
                                )
                            )}
                        </ul>
                    )}
                </>
            </ModalBody>
            <ModalFooter className="px-5">
                <Button color="danger" onClick={action}>
                    {type === 'delete' && 'Delete'}
                    {type === 'resetPassword' && 'Reset Password'}
                </Button>
                <Button color="secondary" onClick={handleClose}>
                    Close
                </Button>
            </ModalFooter>
        </Modal>
    );
}
