import React, { ReactElement, useState, useEffect } from 'react';
import {
    Button,
    Form,
    Row,
    Col,
    FormGroup,
    Label,
    Input,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';
import { Lab } from '../../helpers/requests/interfaces/labInterfaces';
import { Semester } from '../../helpers/requests/interfaces/semesterInterfaces';
import { LabGroupInformation } from '../../helpers/requests/interfaces/labGroupInterfaces';
import { getSemesters } from '../../helpers/requests/semesterRequests';
import { getLabGroups } from '../../helpers/requests/labGroupRequests';

export default function AddStudentButton({
    lab,
    onSubmit,
    semesterAddedNotifier,
    labGroupAddedNotifier,
    onClickHandler,
    successHandler,
}: {
    lab: Lab;
    onSubmit: (event: any) => Promise<void>;
    semesterAddedNotifier: boolean;
    labGroupAddedNotifier: boolean;
    onClickHandler: () => void;
    successHandler: () => void;
}): ReactElement {
    const [inCreate, setInCreate] = useState(false);

    const [selectedSemester, setSelectedSemester] = useState<Semester>(null);
    const [prevSelectedSemester, setPrevSelectedSemester] = useState<Semester>(null);
    const [selectedLabGroup, setSelectedLabGroup] = useState<LabGroupInformation>(null);

    const [semesters, setSemesters] = useState<Semester[]>([]);
    const [labGroups, setLabGroups] = useState<LabGroupInformation[]>([]);

    const [addButtonClicked, setAddButtonClicked] = useState(false);
    const [nrOfStudents, setNrOfStudents] = useState<number>(0);
    const [formIsValid, setFormIsValid] = useState(false);
    const [showAddStudentButton, setShowAddStudentButton] = useState(false);

    useEffect((): void => {
        getSemesters(lab.uuid).then(
            (data): void => {
                setSemesters(data);
            }
        );
    }, [semesterAddedNotifier]);

    useEffect((): void => {
        if (selectedSemester) {
            getLabGroups(lab.uuid, selectedSemester.uuid).then(
                (data): void => {
                    setLabGroups(data);
                }
            );
            if (prevSelectedSemester !== selectedSemester) {
                setSelectedLabGroup(null);
            }
            setPrevSelectedSemester(selectedSemester);
        }
    }, [selectedSemester, labGroupAddedNotifier]);

    useEffect((): void => {
        if (nrOfStudents > 0) {
            setFormIsValid(true);
        } else {
            setFormIsValid(false);
        }
    }, [nrOfStudents]);

    useEffect((): void => {
        if (semesters.length > 0) {
            setShowAddStudentButton(true);
        } else {
            setShowAddStudentButton(false);
        }
    }, [semesters.length]);

    return (
        <>
            <Row className="float-right w-100">
                {inCreate ? (
                    <Form
                        className="w-100"
                        onSubmit={(event: any): void => {
                            setAddButtonClicked(true);
                            onSubmit(event).then(
                                (): void => {
                                    setInCreate(false);
                                    setSelectedSemester(null);
                                    setSelectedLabGroup(null);
                                    setNrOfStudents(0);
                                    setFormIsValid(false);
                                    setAddButtonClicked(false);
                                    successHandler();
                                }
                            );
                        }}
                    >
                        <Row className="float-right w-100">
                            <input hidden style={{ display: 'none' }} name="labUUID" defaultValue={lab.uuid} />
                            <input
                                hidden
                                style={{ display: 'none' }}
                                name="semesterUUID"
                                defaultValue={selectedSemester ? selectedSemester.uuid : ''}
                            />
                            <input
                                hidden
                                style={{ display: 'none' }}
                                name="labGroupUUID"
                                defaultValue={selectedLabGroup ? selectedLabGroup.uuid : ''}
                            />

                            <Col xs={3}>
                                <FormGroup className="w-100">
                                    <Label for="semesterUUID" className="w-100 text-center">
                                        Semester
                                    </Label>
                                    <UncontrolledDropdown>
                                        <DropdownToggle caret className="float-right w-100">
                                            {selectedSemester ? selectedSemester.name : 'Select semester'}
                                        </DropdownToggle>
                                        <DropdownMenu className="justify-content-center w-100">
                                            {semesters.map(
                                                (semester): ReactElement => (
                                                    <DropdownItem
                                                        key={semester.uuid}
                                                        className="mx-auto w-100"
                                                        onClick={(): void => {
                                                            setSelectedSemester(semester);
                                                        }}
                                                    >
                                                        {semester.name}
                                                    </DropdownItem>
                                                )
                                            )}
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </FormGroup>
                            </Col>

                            <Col xs={4}>
                                <FormGroup>
                                    <Label for="labGroupUUID" className="w-100 text-center">
                                        Lab group
                                    </Label>
                                    <UncontrolledDropdown>
                                        <DropdownToggle caret className="float-right w-100">
                                            {selectedLabGroup ? selectedLabGroup.name : 'Select lab group'}
                                        </DropdownToggle>
                                        <DropdownMenu className="justify-content-center w-100">
                                            {labGroups.length === 0 ? (
                                                <DropdownItem disabled> Please create a lab group first </DropdownItem>
                                            ) : (
                                                labGroups.map(
                                                    (labGroup): ReactElement => (
                                                        <DropdownItem
                                                            key={labGroup.uuid}
                                                            className="mx-auto w-100"
                                                            onClick={(): void => {
                                                                setSelectedLabGroup(labGroup);
                                                            }}
                                                        >
                                                            {labGroup.name}
                                                        </DropdownItem>
                                                    )
                                                )
                                            )}
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </FormGroup>
                            </Col>

                            <Col xs={2} style={{ paddingRight: '0' }}>
                                <FormGroup>
                                    <Label for="numberOfStudents" className="text-center w-100">
                                        Number of students
                                    </Label>
                                    <Row className="btn btn-secondary" style={{ padding: '2px' }}>
                                        <Input
                                            className="text-center"
                                            type="number"
                                            name="numberOfStudents"
                                            min="1"
                                            onChange={(e): void => {
                                                setNrOfStudents(e.target.valueAsNumber);
                                            }}
                                        />
                                    </Row>
                                </FormGroup>
                            </Col>
                            <Col xs={{ size: 2, offset: 1 }} className="pl-5">
                                <Row className="h-100 align-items-center">
                                    <Col>
                                        {selectedSemester && selectedLabGroup && formIsValid ? (
                                            <Button
                                                className="my-auto"
                                                type="submit"
                                                color="primary"
                                                onClick={(): void => {
                                                    onClickHandler();
                                                }}
                                                disabled={addButtonClicked}
                                            >
                                                Add
                                            </Button>
                                        ) : (
                                            <Button className="my-auto" type="button" color="primary" disabled>
                                                Add
                                            </Button>
                                        )}
                                    </Col>
                                    <Col>
                                        <Button
                                            color="danger"
                                            className="px-3"
                                            onClick={(): void => {
                                                setInCreate(false);
                                                setSelectedSemester(null);
                                                setSelectedLabGroup(null);
                                                setNrOfStudents(0);
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                ) : (
                    showAddStudentButton && (
                        <Col>
                            <Button
                                color="primary"
                                className="my-auto float-right"
                                onClick={(): void => {
                                    setInCreate(true);
                                }}
                            >
                                Add Student
                            </Button>
                        </Col>
                    )
                )}
            </Row>
        </>
    );
}
