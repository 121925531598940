import React, { Component } from 'react';
import axios from 'axios';
import { History } from 'history';
import { get as getCookie, remove as removeCookie, set as setCookie } from 'es-cookie';
import { decodeToken } from '../../helpers/tokenHelpers';

export const AuthContext = React.createContext({});

export interface UserInfo {
    username: string;
    role: 'teacher' | 'student' | 'admin';
    firstLogin: boolean;
}

export interface AuthState {
    isLoggedIn: boolean;
    user?: UserInfo;
}

export interface AuthContextValue {
    state: AuthState;
    login(event: any, history: History, redirect?: string): Promise<void>;
    changePassword(event: any): Promise<void>;
    logout(): void;
}

export class AuthProvider extends Component<{}, AuthState> {
    public constructor(props: {}) {
        super(props);

        this.login = this.login.bind(this);
        this.logout = this.logout.bind(this);
        this.changePassword = this.changePassword.bind(this);

        const storedState = sessionStorage.getItem('AuthContext');

        if (storedState !== null) {
            this.state = JSON.parse(storedState);
        } else {
            // TODO: since SessionStorage does not persist between tabs, try to get cookie and get data through cookie
            this.state = {
                isLoggedIn: false,
            };
        }
    }

    public componentDidUpdate(prevProps: {}, prevState: AuthState): void {
        if (this.state !== prevState) {
            sessionStorage.setItem('AuthContext', JSON.stringify(this.state));
        }
    }

    public login(event: any, history: History, redirect?: string): Promise<void> {
        event.preventDefault();
        const username = event.target.elements.username.value;
        const password = event.target.elements.password.value;

        return axios
            .post(
                `${process.env.API_URL}/auth`,
                {
                    token: username,
                    password,
                },
                {
                    responseType: 'json',
                }
            )
            .then(
                (response: any): { role: 'teacher' | 'student' | 'admin'; jwt: string; firstLogin: boolean } => {
                    const { data } = response;
                    return data;
                }
            )
            .then(
                (data): void => {
                    const token = data.jwt;
                    const tokenPayload = decodeToken(token);
                    const expires = new Date(tokenPayload.exp * 1000);
                    setCookie('token', token, { expires });
                    const user: UserInfo = { username, role: tokenPayload.role, firstLogin: data.firstLogin };
                    this.setState({ user, isLoggedIn: true });
                }
            )
            .then(
                (): void => {
                    if (redirect) history.push(redirect);
                }
            )
            .catch(
                (error: any): Promise<void> => {
                    return Promise.reject(error);
                }
            );
    }

    public changePassword(event: any): Promise<void> {
        event.preventDefault();
        const oldPassword = event.target.elements.oldPassword.value;
        const newPassword = event.target.elements.newPassword.value;
        const { user } = this.state;
        const { role } = user;

        return axios
            .post(
                `${process.env.API_URL}/${role}/password-change`,
                {
                    oldPassword,
                    newPassword,
                },
                {
                    responseType: 'json',
                    headers: { Authorization: `Bearer ${getCookie('token')}` },
                }
            )
            .then(
                (): void => {
                    if (user && user.firstLogin)
                        this.setState(
                            (prevState): AuthState => ({ ...prevState, user: { ...prevState.user, firstLogin: false } })
                        );
                }
            )
            .catch(
                (error: any): Promise<void> => {
                    return Promise.reject(error);
                }
            );
    }

    public logout(): void {
        removeCookie('token');
        this.setState({ isLoggedIn: false, user: undefined });
    }

    // TODO: figure out type
    public render(): any {
        const { children } = this.props;
        return (
            <AuthContext.Provider
                value={{
                    state: this.state,
                    login: this.login,
                    logout: this.logout,
                    changePassword: this.changePassword,
                }}
            >
                {children}
            </AuthContext.Provider>
        );
    }
}
