import React, { ReactElement } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Breadcrumbs from 'react-router-dynamic-breadcrumbs';
import LabEditForm from '../../components/Forms/LabEditForm';
import { Lab } from '../../helpers/requests/interfaces/labInterfaces';
import { getLab, putLab } from '../../helpers/requests/labRequests';

interface LabEditState {
    lab?: Lab;
}

class LabEdit extends React.Component<RouteComponentProps<{ labUUID: string }>, LabEditState> {
    public constructor(props: RouteComponentProps<{ labUUID: string }>) {
        super(props);

        this.state = {};
    }

    public componentWillMount(): void {
        const { match } = this.props;
        const { labUUID } = match.params;

        getLab(labUUID).then(
            (lab): void => {
                this.setState({ lab });
            }
        );
    }

    public render(): ReactElement {
        const { lab } = this.state;
        return (
            <>
                {lab && (
                    <>
                        {' '}
                        <Breadcrumbs
                            mappedRoutes={{
                                '/': 'Home',
                                '/labs': 'Labs',
                                '/images': 'Images',
                                '/labs/:labUUID': lab.name,
                                '/labs/:labUUID/edit': 'Edit',
                            }}
                        />
                        <LabEditForm
                            onSubmit={(event: any): void => {
                                event.preventDefault();
                                const name = event.target.elements.name.value;
                                const abbreviation = event.target.elements.abbreviation.value;
                                const imageId = event.target.elements.imageId.value;
                                const password = event.target.elements.password.value;
                                const data = {
                                    name,
                                    abbreviation,
                                    imageId,
                                    password,
                                };
                                console.log('Updating Lab:', data);

                                const { match } = this.props;
                                const { labUUID } = match.params;

                                putLab(labUUID, data).then(
                                    (): void => {
                                        console.log('Update successful');
                                        const { history } = this.props;
                                        history.push('/labs');
                                    }
                                );
                            }}
                            onCancel={(): void => {
                                const { history } = this.props;
                                history.goBack();
                            }}
                            labName={lab.name}
                            labAbbreviation={lab.abbreviation}
                            labImageId={lab.imageId}
                            labPassword={lab.password}
                        />
                    </>
                )}
            </>
        );
    }
}

export default LabEdit;
