import React, { ReactElement } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

export interface RowSelectInformation {
    uuid: string;
}

export interface SemesterRowSelectInformation {
    uuid: string;
    labUuid: string;
}

interface TableProps {
    displayData: RowSelectInformation[];
    onRowSelect?: (selected: string[]) => void;
    onRowClick?: (event: any, row: any, rowIdx?: number) => void;
    columns: ColumnsJSON[];
}

export function test(): ReactElement {
    return <h1>Test</h1>;
}

export interface ColumnsJSON {
    dataField: string;
    text: string;
    sort?: boolean;
    filter?: any;
    hidden?: boolean;
    isDummyField?: boolean;
    formatter?: any;
}
export default class DataTable extends React.Component<TableProps, {}> {
    private selectRow = {
        mode: 'checkbox',
        clickToSelect: true,
        onSelect: (row: RowSelectInformation, isSelect: boolean): void => {
            if (isSelect) {
                this.selected.push(row.uuid);
            } else {
                this.selected = this.selected.filter(
                    (selected: string): boolean => {
                        return selected !== row.uuid;
                    }
                );
            }
            const { onRowSelect } = this.props;
            onRowSelect(this.selected);
        },
        onSelectAll: (isSelect: boolean): void => {
            if (isSelect) {
                const allRows: string[] = [];
                const { displayData } = this.props;
                displayData.map(
                    (row: RowSelectInformation): number => {
                        return allRows.push(row.uuid);
                    }
                );
                this.selected = allRows;
            } else {
                this.selected = [];
            }
            const { onRowSelect } = this.props;
            onRowSelect(this.selected);
        },
    };

    private selected: string[] = [];

    public render(): ReactElement {
        const { displayData, columns, onRowSelect, onRowClick } = this.props;
        return (
            <BootstrapTable
                hover
                bootstrap4
                keyField="uuid"
                data={displayData}
                columns={columns}
                rowEvents={{
                    onClick: onRowClick || ((): void => {}),
                }}
                rowStyle={onRowClick || onRowSelect ? { cursor: 'pointer' } : undefined}
                selectRow={onRowSelect ? this.selectRow : undefined}
            />
        );
    }
}
