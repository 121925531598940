import React, { ReactElement } from 'react';
import { Button, UncontrolledTooltip } from 'reactstrap';
import Info from '@material-ui/icons/Info';
import { Link } from 'react-router-dom';

export default function InfoButton({
    link,
    className,
    tooltipText,
    id,
}: {
    link: string;
    className?: string;
    tooltipText?: string;
    id?: string;
}): ReactElement {
    return (
        <>
            <Button
                className={`${className} p-2`}
                color="primary"
                tag={Link}
                to={link}
                id={id !== undefined ? `infoButton_${id}` : 'infoButton'}
                onClick={(event: any): void => {
                    event.stopPropagation();
                }}
            >
                <Info />
            </Button>
            {id && tooltipText && (
                <UncontrolledTooltip placement="top" target={`infoButton_${id}`}>
                    {tooltipText}
                </UncontrolledTooltip>
            )}
        </>
    );
}
