import React, { ReactElement } from 'react';
import { Button, UncontrolledTooltip } from 'reactstrap';
import Delete from '@material-ui/icons/Delete';

export default function DeleteButton({
    onClick,
    className,
    tooltipText,
    id,
    isDisabled,
}: {
    onClick: () => void;
    className: string;
    tooltipText?: string;
    id?: string;
    isDisabled?: boolean;
}): ReactElement {
    return (
        <>
            <Button
                className={`${className} p-2`}
                color="danger"
                disabled={isDisabled}
                onClick={(event: any): void => {
                    event.stopPropagation();
                    onClick();
                }}
                id={id !== undefined ? `deleteButton_${id}` : 'deleteButton'}
            >
                <Delete />
            </Button>
            {id && tooltipText && (
                <UncontrolledTooltip placement="top" target={`deleteButton_${id}`}>
                    {tooltipText}
                </UncontrolledTooltip>
            )}
        </>
    );
}
