import React, { ReactElement } from 'react';
import { RouteProps, Route, withRouter } from 'react-router-dom';
import { get as getCookie, remove as removeCookie } from 'es-cookie';
import { checkTokenIsValid } from '../../helpers/tokenHelpers';
import Login from '../../pages/Login/Login';
import ChangePassword from '../../pages/Profile/ChangePassword';
import { AuthContext, AuthContextValue } from './AuthContext';

export default function AuthenticatedRoute(props: RouteProps): ReactElement {
    const { component } = props;

    function CheckAuthenticatedWrapper(context: AuthContextValue): ReactElement {
        const token = getCookie('token');
        const { state } = context;
        const { user } = state;

        if (checkTokenIsValid(token)) {
            if (!user.firstLogin) {
                const WrappedComponent = withRouter(component);
                return <WrappedComponent />;
            }
            return <ChangePassword firstLogin />;
        }

        removeCookie('token');
        // return <Redirect to="/login" />; // change page to Login Page
        // display the Login Page in the current Page (going back after Login doesn't result in going back to Login Page)
        return <Login />;
    }

    return (
        <AuthContext.Consumer>
            {(context: AuthContextValue): ReactElement => (
                <Route {...props} component={(): ReactElement => CheckAuthenticatedWrapper(context)} />
            )}
        </AuthContext.Consumer>
    );
}
