import React, { ReactElement, useState } from 'react';
import { Button, Form, Input, Row, Col, FormGroup, Label } from 'reactstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

interface DatePickerState {
    start: Date;
    end: Date;
}

interface InputState {
    name: string;
}

export default function AddSemesterButton({
    submit,
    handler,
}: {
    submit: (event: any) => Promise<void>;
    handler: () => void;
}): ReactElement {
    const [inCreate, setInCreate] = useState(false);
    const currentDate = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(currentDate.getDate() + 1);

    const [datePickerState, setDatePickerState] = useState<DatePickerState>({
        start: currentDate,
        end: tomorrow,
    });

    const [inputState, setInputState] = useState<InputState>({
        name: '',
    });

    return (
        <>
            {inCreate ? (
                <Form
                    className="bg-info mb-1 mx-2 p-2"
                    style={{ borderRadius: '0.2rem', display: 'inline-block' }}
                    onSubmit={(event): void => {
                        submit(event).then(
                            (): void => {
                                setInCreate(false);
                            }
                        );
                    }}
                >
                    <Col xs={{ size: 10, offset: 1 }}>
                        <Row className="mb-3">
                            <FormGroup className="my-0 pb-1">
                                <Label for="semesterName" hidden style={{ display: 'none' }}>
                                    Semester name
                                </Label>
                                <Input
                                    id="semesterName"
                                    className="pl-3 pr-1 py-0"
                                    type="text"
                                    name="semesterName"
                                    placeholder="Semester name"
                                    autoFocus
                                    onChange={(event): void => {
                                        setInputState({ name: event.target.value });
                                    }}
                                />
                            </FormGroup>
                        </Row>

                        <Row className="mb-2">
                            <Col xs={4} className="pl-0 text-right">
                                <span>start:</span>
                            </Col>

                            <Col xs={8} className="px-0">
                                <DatePicker
                                    className="w-100 text-center bg-info"
                                    name="startDate"
                                    id="startDate"
                                    customInput={
                                        <input
                                            id="startDate"
                                            name="startDate"
                                            type="text"
                                            style={{ border: '0', borderBottom: '1px solid' }}
                                        />
                                    }
                                    selected={datePickerState.start}
                                    selectsStart
                                    startDate={datePickerState.start}
                                    endDate={datePickerState.end}
                                    dateFormat="dd/MM/yy"
                                    minDate={currentDate}
                                    onChange={(newDate: Date): void => {
                                        setDatePickerState(
                                            (prevSate): DatePickerState => {
                                                if (prevSate.end.valueOf() - newDate.valueOf() <= 0) {
                                                    return { start: newDate, end: newDate };
                                                }
                                                return { ...prevSate, start: newDate };
                                            }
                                        );
                                    }}
                                />
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col xs={4} className="pl-0 text-right">
                                <span>end:</span>
                            </Col>
                            <Col xs={8} className="px-0">
                                <DatePicker
                                    className="w-100 text-center bg-info"
                                    name="endDate"
                                    id="endDate"
                                    customInput={
                                        <input
                                            id="endDate"
                                            name="endDate"
                                            type="text"
                                            style={{ border: '0', borderBottom: '1px solid' }}
                                        />
                                    }
                                    selected={datePickerState.end}
                                    selectsEnd
                                    startDate={datePickerState.start}
                                    endDate={datePickerState.end}
                                    dateFormat="dd/MM/yy"
                                    minDate={tomorrow}
                                    onChange={(newDate: Date): void => {
                                        setDatePickerState(
                                            (prevSate): DatePickerState => {
                                                if (newDate.valueOf() - prevSate.start.valueOf() <= 0) {
                                                    return { start: newDate, end: newDate };
                                                }
                                                return { ...prevSate, end: newDate };
                                            }
                                        );
                                    }}
                                />
                            </Col>
                        </Row>

                        <Row className="justify-content-center mt-2">
                            <Col xs={6} style={{ padding: '0' }}>
                                {inputState.name.length > 0 ? (
                                    <Button className="px-2" type="submit" onClick={handler} color="primary">
                                        Create
                                    </Button>
                                ) : (
                                    <Button className="px-2" type="submit" color="primary" disabled>
                                        Create
                                    </Button>
                                )}
                            </Col>
                            <Col xs={6} style={{ padding: '0' }}>
                                <Button
                                    className="px-2"
                                    type="button"
                                    color="danger"
                                    onClick={(): void => {
                                        setInCreate(false);
                                    }}
                                >
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Form>
            ) : (
                <Button
                    onClick={(): void => {
                        setInCreate(true);
                    }}
                    color="primary"
                >
                    Add semester
                </Button>
            )}
        </>
    );
}
