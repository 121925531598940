import React, { ReactElement } from 'react';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';
import getDoc from '../../helpers/requests/documentationRequests';

interface DocumentationState {
    doc: JSON;
}
export default class Documentation extends React.Component<{}, DocumentationState> {
    public constructor(props: {}) {
        super(props);

        this.state = {
            doc: JSON,
        };
    }

    public async componentWillMount(): Promise<void> {
        await getDoc()
            .then(
                (doc: JSON): void => {
                    this.setState({ doc });
                }
            )
            .catch(
                (error: Error): void => {
                    console.log('Error when retreiving doc!');
                    console.log(error);
                }
            );
    }

    public render(): ReactElement {
        const { doc } = this.state;

        return <SwaggerUI spec={doc} />;
    }
}
