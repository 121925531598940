import React, { ReactElement } from 'react';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Semester } from '../../../helpers/requests/interfaces/semesterInterfaces';

export default function SemesterInfoButton({
    semester,
    classname,
}: {
    semester: Semester;
    classname?: string;
}): ReactElement {
    const start = new Date(semester.startDate);
    const end = new Date(semester.endDate);

    return (
        <Button className={classname} tag={Link} to={`/labs/${semester.labUUID}/semesters/${semester.uuid}`}>
            <p className="text-center" style={{ fontWeight: 'bold' }}>
                {semester.name}
            </p>
            <div>start: {start.toLocaleDateString()}</div>
            <div>end: {end.toLocaleDateString()}</div>
        </Button>
    );
}
