import * as React from 'react';
import {
    Navbar,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Dropdown,
} from 'reactstrap';
import { Link as RRNavLink, Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { AuthContext, AuthContextValue } from '../Auth/AuthContext';
import logo from '../../assets/images/CloudLabLogo.png';

export interface NavigationElement {
    name: string;
    link: string;
}

interface NavbarProps extends RouteComponentProps {
    navigationElements: NavigationElement[];
}

interface NavbarState {
    isOpen: boolean;
}

class MyNavbar extends React.Component<NavbarProps, NavbarState> {
    private static navigationElementFactory(
        navigationElement: NavigationElement,
        location: string
    ): React.ReactElement {
        const isActive = location.startsWith(navigationElement.link);
        return (
            <NavItem key={navigationElement.link} active={isActive} className="mx-2">
                <NavLink to={navigationElement.link} tag={RRNavLink}>
                    {navigationElement.name}
                </NavLink>
            </NavItem>
        );
    }

    public constructor(props: NavbarProps) {
        super(props);

        this.hamburgerRef = React.createRef<HTMLDivElement>();
        this.toggle = this.toggle.bind(this);
        this.state = {
            isOpen: false,
        };
    }

    private hamburgerRef: React.RefObject<HTMLDivElement>;

    public toggle(): void {
        this.hamburgerRef.current.classList.toggle('is-active');
        const { isOpen } = this.state;
        if (isOpen) this.hamburgerRef.current.parentElement.blur();
        this.setState((prevState): NavbarState => ({ isOpen: !prevState.isOpen }));
    }

    private renderProfileLink(isLoggedIn: boolean, logout: () => void): React.ReactElement {
        const { isOpen } = this.state;
        const loggedInView = (
            <Dropdown isOpen={isOpen} toggle={this.toggle}>
                <DropdownToggle nav>
                    <div
                        ref={this.hamburgerRef}
                        className="hamburger hamburger--elastic"
                        role="button"
                        style={{ display: 'inline-flex', flexDirection: 'column', justifyContent: 'center' }}
                    >
                        <span className="hamburger-box">
                            <span className="hamburger-inner" />
                        </span>
                        <span className="hamburger-text">User</span>
                    </div>
                </DropdownToggle>
                <DropdownMenu right>
                    <DropdownItem to="/change-password" className="nav-link" tag={RRNavLink}>
                        Change password
                    </DropdownItem>
                    <DropdownItem to="/feedback" className="nav-link" tag={RRNavLink}>
                        Feedback
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem to="/manual" className="nav-link" tag={RRNavLink}>
                        Manual
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem
                        onClick={(): void => {
                            window.open('https://www.hs-esslingen.de/en/imprint-and-legal-notice/');
                        }}
                        className="nav-link"
                        tag={RRNavLink}
                    >
                        Imprint and Legal Notice
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem
                        onClick={(): void => {
                            logout();
                        }}
                        className="nav-link"
                        to="/"
                        tag={RRNavLink}
                    >
                        Logout
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        );

        if (isLoggedIn) return loggedInView;

        return <></>;
    }

    public render(): React.ReactElement {
        const { navigationElements, location } = this.props;
        return (
            <AuthContext.Consumer>
                {(context: AuthContextValue): React.ReactElement => (
                    <Navbar color="light" light expand="md" className="px-4">
                        <NavbarBrand tag={Link} to="/">
                            <img src={logo} alt="CloudLab Logo" className="navbar-logo" />
                        </NavbarBrand>
                        {context.state.user && context.state.user.role && (
                            <Nav className="mx-auto px-4">
                                {context.state.user.role.toLowerCase() !== 'student' && (
                                    <h4 className="m-0 mx-lg-2 mr-sm-auto">
                                        {`${context.state.user.role
                                            .charAt(0)
                                            .toUpperCase()}${context.state.user.role.slice(1)}`}
                                        -Dashboard
                                    </h4>
                                )}
                            </Nav>
                        )}
                        {/* <NavbarToggler onClick={this.toggle}>Toggle</NavbarToggler>
                        <Collapse isOpen={isOpen} navbar> */}
                        <Nav className="ml-2 mr-auto">
                            {navigationElements.map(
                                (item): any => {
                                    return MyNavbar.navigationElementFactory(item, location.pathname);
                                }
                            )}
                        </Nav>
                        <Nav className="ml-auto mr-2">
                            {this.renderProfileLink(context.state.isLoggedIn, context.logout)}
                        </Nav>
                        {/* </Collapse> */}
                    </Navbar>
                )}
            </AuthContext.Consumer>
        );
    }
}

export default withRouter(MyNavbar);
