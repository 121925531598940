import React, { ReactElement } from 'react';
import { HashRouter as Router, Route } from 'react-router-dom';
import { Container } from 'reactstrap';
import Login from '../../pages/Login/Login';
import MyNavbar, { NavigationElement } from '../Navbar/Navbar';
import { AuthContext, AuthContextValue } from '../Auth/AuthContext';
import Dashboard from '../../pages/Dashboard/Dashboard';
import Feedback from '../../pages/Feedback/Feedback';
import Images from '../../pages/Images/Images';
import VMStatusPage from '../../pages/VMStatusPage/VMStatusPage';
import Labs from '../../pages/Labs/Labs';
import LabEdit from '../../pages/Labs/LabEdit';
import LabInfo from '../../pages/Labs/LabInfo';
import LabSemester from '../../pages/Labs/LabSemester';
import LabGroup from '../../pages/Labs/LabGroup';
import ChangePassword from '../../pages/Profile/ChangePassword';
import Documentation from '../../pages/Documentation/Documentation';
import DisplayOrientationAlert from '../Alerts/DisplayOrientationAlert';
import AuthenticatedRoute from '../Auth/AuthenticatedRoute';
import Semesters from '../../pages/Semesters/Semesters';

export interface Page {
    name: string;
    link: string;
    component: React.ComponentClass | React.FunctionComponent;
}

// TODO: where should we put you?
function getPagesByRole(role: string): Page[] {
    // TODO: correct arrays; admin super set of teacher?
    if (role === 'teacher') {
        return [
            { name: 'Labs', link: '/labs', component: Labs },
            // to reactivate the student overview delete this comment und uncomment the line below.
            // { name: 'Students', link: '/students', component: Students },
            { name: 'Semesters', link: '/semesters', component: Semesters },
            { name: 'Images', link: '/images', component: Images },
        ];
    }
    if (role === 'student') {
        return [{ name: 'VM Status', link: '/vm-status', component: VMStatusPage }];
    }
    if (role === 'admin') {
        return [{ name: 'Swagger Docu', link: '/docu', component: Documentation }];
    }
    return [];
}

function getManualPathByRole(role: string): string {
    if (role === 'teacher') {
        return '/manual/CloudlabManualProf.md.html';
    }
    if (role === 'student') {
        return '/manual/CloudlabManualStud.md.html';
    }
    if (role === 'admin') {
        return '';
    }
    return '';
}

export default function App(): ReactElement {
    return (
        <AuthContext.Consumer>
            {(context: AuthContextValue): React.ReactElement => {
                try {
                    const { role } = context.state.user;
                    const pages = getPagesByRole(role);
                    const navElements: NavigationElement[] = pages.map(
                        (page): NavigationElement => ({ name: page.name, link: page.link })
                    );

                    return (
                        <Router>
                            <MyNavbar navigationElements={navElements} />
                            <DisplayOrientationAlert />
                            <Container className="pt-3">
                                <AuthenticatedRoute
                                    path="/"
                                    exact
                                    component={(): ReactElement => <Dashboard pages={pages} />}
                                />
                                {pages.map(
                                    (page: Page): ReactElement => {
                                        return (
                                            <AuthenticatedRoute
                                                path={page.link}
                                                exact
                                                component={page.component}
                                                key={page.link}
                                            />
                                        );
                                    }
                                )}
                                <AuthenticatedRoute exact path="/change-password" component={ChangePassword} />
                                <AuthenticatedRoute exact path="/labs/:labUUID/edit" component={LabEdit} />
                                <AuthenticatedRoute exact path="/labs/:labUUID" component={LabInfo} />

                                <AuthenticatedRoute path="/feedback" component={Feedback} />
                                <AuthenticatedRoute
                                    exact
                                    path="/labs/:labUUID/semesters/:semesterUUID"
                                    component={LabSemester}
                                />
                                <AuthenticatedRoute
                                    exact
                                    path="/labs/:labUUID/semesters/:semesterUUID/labgroups/:labgroupUUID"
                                    component={LabGroup}
                                />
                                <Route
                                    path="/manual"
                                    component={(): ReactElement => {
                                        window.location.href = `http://${window.location.host}${getManualPathByRole(
                                            role
                                        )}`;
                                        return null;
                                    }}
                                />
                                <AuthenticatedRoute path="/docs" component={Documentation} />
                            </Container>
                        </Router>
                    );
                } catch (e) {
                    return (
                        <Router>
                            <MyNavbar navigationElements={[]} />
                            <DisplayOrientationAlert />
                            <Container className="pt-3">
                                <Route path="/" exact component={(): ReactElement => <Login redirect="/" />} />
                                <Route path="/login" exact component={(): ReactElement => <Login redirect="/" />} />
                            </Container>
                        </Router>
                    );
                }
            }}
        </AuthContext.Consumer>
    );
}
