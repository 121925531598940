import React, { ReactElement } from 'react';
import { Row, Col, Alert, Button, Input, Label, Spinner } from 'reactstrap';
import { Link } from 'react-router-dom';
import Create from '@material-ui/icons/Create.js';
import { RouteComponentProps } from 'react-router';
import Breadcrumbs from 'react-router-dynamic-breadcrumbs';
import DataTable, { ColumnsJSON } from '../../components/Teacher/DataTables/DataTable';
import LabGroupInfoButton from '../../components/Table/SemesterLabGroupTable/LabGroupInfoButton';
import ExportButton from '../../components/Button/ExportButton';
import InstanceActionButton from '../../components/Button/InstanceActionButton';
import { Lab } from '../../helpers/requests/interfaces/labInterfaces';
import { LabGroupInformation } from '../../helpers/requests/interfaces/labGroupInterfaces';
import { getLab } from '../../helpers/requests/labRequests';
import { getLabGroups } from '../../helpers/requests/labGroupRequests';
import { Semester } from '../../helpers/requests/interfaces/semesterInterfaces';
import { getSemester, putSemester, deleteSemester } from '../../helpers/requests/semesterRequests';

import {
    getSemesterStudents,
    patchSemesterStudents,
    patchSingleStudent,
    resetStudentPassword,
    deleteStudent,
} from '../../helpers/requests/studentRequests';
import { StudentAndInstance } from '../../helpers/requests/interfaces/studentInterfaces';
import DeleteButton from '../../components/Button/DeleteButton';
import ConfirmModal from '../../components/Modal/ConfirmModal';

interface LabSemesterStudentDisplayData {
    token: string;
    labGroup: string;
    state: string;
    uuid: string;
}

interface LabSemesterProps {
    match: {
        params: {
            labUUID: string;
            semesterUUID: string;
        };
    };
}

interface LabSemesterState {
    labInfo: Lab;
    semesterInfo: Semester;
    students: StudentAndInstance[];
    labGroups: LabGroupInformation[];
    displayData: LabSemesterStudentDisplayData[];
    selected: string[];
    editName: boolean;
    inputName: string;
    showDeleteConfirmation: boolean;
    deleteUuid: string;
    alert: {
        showAlert: boolean;
        alertMessage: string;
        alertColor: string;
    };
    loading: boolean;
}

export default class LabSemester extends React.Component<LabSemesterProps & RouteComponentProps, LabSemesterState> {
    private columns: ColumnsJSON[] = [
        {
            dataField: 'token',
            text: 'Token',
            sort: true,
        },
        {
            dataField: 'labGroup',
            text: 'Lab Group',
            sort: true,
        },
        {
            dataField: 'state',
            text: 'VM State',
            sort: true,
        },
        {
            dataField: 'uuid',
            text: '',
            hidden: true,
        },
    ];

    public constructor(props: LabSemesterProps & RouteComponentProps) {
        super(props);

        this.state = {
            labInfo: null,
            semesterInfo: null,
            students: [],
            labGroups: [],
            displayData: [],
            selected: [],
            editName: false,
            inputName: '',
            showDeleteConfirmation: false,
            deleteUuid: '',
            alert: {
                showAlert: false,
                alertMessage: '',
                alertColor: '',
            },
            loading: false,
        };
    }

    public async componentWillMount(): Promise<void> {
        const { match } = this.props;
        const { labUUID, semesterUUID } = match.params;
        this.setState({loading: true});

        await getLab(labUUID).then(
            (labInfo): void => {
                this.setState((prevState): LabSemesterState => ({ ...prevState, labInfo }));
            }
        );
        await getSemester(labUUID, semesterUUID).then(
            (semesterInfo): void => {
                this.setState((prevState): LabSemesterState => ({ ...prevState, semesterInfo }));
                this.setState({ inputName: semesterInfo.name });
            }
        );

        const { semesterInfo } = this.state;
        if (!semesterInfo.toString().includes('404')) {
            this.fetchDataForDataTable();
        } else {
            const { history } = this.props;
            history.goBack();
        }
    }

    private onRowSelect = (selectedRows: string[]): void => {
        this.setState({ selected: selectedRows });
    };

    private setAlert(alertMessage: string, alertColor: string, alertDurationOfAlert?: number): void {
        const alertDuration = alertDurationOfAlert || 10000;

        // TODO: Possibly handle alert timeouts with window.clearTimeout

        this.setState(
            {
                alert: {
                    alertColor,
                    alertMessage,
                    showAlert: true,
                },
            },
            (): void => {
                window.setTimeout((): void => {
                    this.setState({
                        alert: {
                            alertColor: '',
                            alertMessage: '',
                            showAlert: false,
                        },
                    });
                }, alertDuration);
            }
        );
    }

    private changeName = (e: { target: { value: string } }): void => {
        this.setState({ inputName: e.target.value });
    };

    public hideDeleteModal = (): void => {
        this.setState({ showDeleteConfirmation: false });
    };

    public deleteLabsemester = (): void => {
        const { match } = this.props;
        const { labUUID, semesterUUID } = match.params;
        deleteSemester(labUUID, semesterUUID).then(
            (): void => {
                console.log('Deleted semester successful');
                const { history } = this.props;
                history.push(`/labs/${labUUID}`);
            }
        );
    };

    public async fetchDataForDataTable(): Promise<void> {
        const { match } = this.props;
        const { labUUID, semesterUUID } = match.params;

        await getSemesterStudents(labUUID, semesterUUID).then(
            (students): void => {
                this.setState((prevState): LabSemesterState => ({ ...prevState, students }));
            }
        ).finally(
            ():void=>{this.setState({loading: false});}
        );
        await getLabGroups(labUUID, semesterUUID).then(
            (labGroups): void => {
                this.setState((prevState): LabSemesterState => ({ ...prevState, labGroups }));
            }
        );

        this.composeDisplayData();
    }

    private composeDisplayData(): void {
        const { students, labGroups } = this.state;
        let atLeastOneStatePendingOrStopping = false;
        const displayData = students.map(
            (studentAndInstance: StudentAndInstance): LabSemesterStudentDisplayData => {
                const stateVM =
                    (studentAndInstance.instanceInfo && studentAndInstance.instanceInfo.State) || 'Not yet created';
                if (stateVM !== 'stopped' || 'running' || 'Not yet created') {
                    atLeastOneStatePendingOrStopping = true;
                }
                const labGroupName =
                    labGroups.find(
                        (labGroup: LabGroupInformation): boolean =>
                            studentAndInstance.studentInfo.labGroupUUID === labGroup.uuid
                    ).name || ' ';

                const item = {
                    token: studentAndInstance.studentInfo.token,
                    labGroup: labGroupName,
                    state: stateVM,
                    uuid: studentAndInstance.studentInfo.uuid,
                };
                return item;
            }
        );
        this.setState({ displayData });

        // Fetch new information every 10 seconds if any instance is either currently not running, stopped or not yet created
        if (atLeastOneStatePendingOrStopping) {
            setTimeout((): void => {
                this.fetchDataForDataTable();
            }, 10000);
        }
    }

    private showErrorAlert(error: any, action: string): void {
        const { status } = error.response.data;
        const { summary } = error.response.data;
        const { details } = error.response.data;
        // TODO: Proper error handling
        this.setAlert(
            `Could not successfully ${action}. Failed with error: ${status}: ${summary} - ${details}`,
            'danger'
        );
    }

    private deleteStudentWithErrorHandling(uuid: string, action: string): void {
        deleteStudent(uuid)
            .then(
                (): void => {
                    this.setAlert(`Selected students did successfully ${action}.`, 'success');
                    this.fetchDataForDataTable();
                }
            )
            .catch(
                (error: any): Promise<void> => {
                    this.showErrorAlert(error, action);
                    return error;
                }
            );
    }

    private resetStudentPasswordWithErrorHandling(uuid: string, action: string): void {
        resetStudentPassword(uuid)
            .then(
                (): void => {
                    this.setAlert(`Selected students did successfully ${action}.`, 'success');
                }
            )
            .catch(
                (error: any): Promise<void> => {
                    this.showErrorAlert(error, action);
                    return error;
                }
            );
    }

    private submitEdit(): void {
        const { semesterInfo } = this.state;
        const { inputName } = this.state;
        const name = inputName;
        const { startDate } = semesterInfo;
        const { endDate } = semesterInfo;
        const data = {
            name,
            startDate,
            endDate,
        };
        console.log('Updating Semester:', data);

        const { match } = this.props;
        const { labUUID, semesterUUID } = match.params;

        putSemester(labUUID, semesterUUID, name, startDate, endDate).then(
            (): void => {
                const newSemesterInfo = semesterInfo;
                newSemesterInfo.name = name;
                newSemesterInfo.startDate = semesterInfo.startDate;
                newSemesterInfo.endDate = semesterInfo.endDate;
                console.log('Update successful');
                this.setState({ editName: false, semesterInfo: newSemesterInfo });
            }
        );
    }

    public render(): ReactElement {
        const {
            semesterInfo,
            labInfo,
            displayData,
            labGroups,
            selected,
            editName,
            showDeleteConfirmation,
            alert /* otherSemesters */,
            loading,
        } = this.state;
        const { match } = this.props;
        const { semesterUUID } = match.params;
        return (
            <>
                {labInfo && semesterInfo && (
                    <>
                        <Breadcrumbs
                            mappedRoutes={{
                                '/': 'Home',
                                '/labs': 'Labs',
                                '/images': 'Images',
                                '/labs/:labUUID': labInfo.name,
                                '/labs/:labUUID/edit': 'Edit',
                                '/labs/:labUUID/semesters': null,
                                '/labs/:labUUID/semesters/:sid': semesterInfo.name,
                            }}
                        />
                        <Row className="justify-content-center mb-5">
                            <h1>
                                <Link className="text-black" to={`/labs/${labInfo.uuid}`}>
                                    {labInfo.name}
                                </Link>
                            </h1>
                        </Row>
                        <div>
                            {editName ? (
                                <Row className="mb-3">
                                    <Col xs={{ size: 3, offset: 1 }} style={{ bottom: '10px' }}>
                                        <h2 className="text-right">Semester:</h2>
                                    </Col>
                                    <Col
                                        xs={4}
                                        className="text-left"
                                        style={{ paddingRight: '15px', bottom: '8px', paddingTop: '7px' }}
                                    >
                                        {/* <h2>{semesterInfo.name}</h2> */}
                                        <Input
                                            onChange={this.changeName}
                                            type="text"
                                            name="name"
                                            defaultValue={semesterInfo.name}
                                            className="col-6"
                                            style={{ paddingTop: '0' }}
                                        />
                                    </Col>
                                    <Col
                                        md={2}
                                        lg={1}
                                        style={{ marginTop: '-10px', paddingLeft: '0px', paddingRight: '0px' }}
                                    >
                                        <Button
                                            type="button"
                                            color="primary"
                                            style={{ padding: '10px' }}
                                            onClick={(): void => {
                                                this.submitEdit();
                                            }}
                                        >
                                            Submit
                                        </Button>
                                    </Col>
                                    <Col
                                        md={2}
                                        lg={1}
                                        style={{ marginTop: '-10px', paddingLeft: '0px', paddingRight: '0px' }}
                                    >
                                        <Button
                                            color="danger"
                                            style={{ padding: '10px' }}
                                            onClick={(): void => {
                                                this.setState({
                                                    editName: false,
                                                });
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    </Col>
                                </Row>
                            ) : (
                                <Row className="mb-3">
                                    <Col xs={{ size: 3, offset: 1 }} style={{ bottom: '10px' }}>
                                        <h2 className="text-right">Semester:</h2>
                                    </Col>
                                    <Col
                                        xs={4}
                                        className="text-left"
                                        style={{ paddingLeft: '0px', paddingRight: '0px', bottom: '8px' }}
                                    >
                                        <h2>{semesterInfo.name}</h2>
                                    </Col>
                                    <Col
                                        md={2}
                                        lg={1}
                                        style={{ marginTop: '-10px', paddingLeft: '0px', paddingRight: '0px' }}
                                    >
                                        <Button
                                            className="px-3 mx-2 p-2"
                                            color="primary"
                                            id="editButton"
                                            onClick={(): void => {
                                                this.setState({
                                                    editName: true,
                                                });
                                            }}
                                        >
                                            <Create />
                                            <span>Edit</span>
                                        </Button>
                                    </Col>
                                    <Col md={2} lg={1} style={{ marginTop: '-10px', paddingLeft: '0px' }}>
                                        <DeleteButton
                                            tooltipText="Delete lab group"
                                            className="ml-3"
                                            onClick={(): void => {
                                                this.setState({
                                                    showDeleteConfirmation: true,
                                                    deleteUuid: semesterUUID,
                                                });
                                            }}
                                        />
                                    </Col>
                                </Row>
                            )}
                        </div>
                    </>
                )}

                <Row className="mb-4">
                    <Col xs={{ size: 3, offset: 1 }}>
                        <h3 className="text-right">Lab groups:</h3>
                    </Col>
                    <Col xs={7}>
                        {labGroups &&
                            labGroups.map(
                                (labGroup: LabGroupInformation): ReactElement => (
                                    <LabGroupInfoButton labgroup={labGroup} key={labGroup.uuid} classname="mx-3" />
                                )
                            )}
                    </Col>
                </Row>

                <Row className="my-2">
                    {alert.showAlert && <Alert color={alert.alertColor}>{alert.alertMessage} </Alert>}
                    <Col xs={{ size: 5, offset: 3 }} lg={{ size: 5, offset: 3 }} className="justify-content-right" />
                    <Col sm={3} lg={2}>
                        <Row className="h-100 justify-content-center">
                            <InstanceActionButton
                                isDisabled={selected.length === 0}
                                selected={displayData.filter((student): boolean => selected.includes(student.uuid))}
                                onSubmit={(action): Promise<void> => {
                                    const { params } = match;
                                    const { labUUID } = params;

                                    this.setAlert(`Request to ${action} sent. Please wait.`, 'success');
                                    if (
                                        action === 'terminate' ||
                                        action === 'start' ||
                                        action === 'stop' ||
                                        action === 'recreate'
                                    ) {
                                        if (selected.length === displayData.length) {
                                            return patchSemesterStudents(labUUID, semesterUUID, action)
                                                .then(
                                                    (): void => {
                                                        this.setAlert(
                                                            `Selected instances did successfully ${action}.`,
                                                            'success'
                                                        );
                                                        this.fetchDataForDataTable();
                                                    }
                                                )
                                                .catch(
                                                    (error: any): Promise<void> => {
                                                        const { status } = error.response.data;
                                                        const { summary } = error.response.data;
                                                        const { details } = error.response.data;
                                                        // TODO: Proper error handling
                                                        this.setAlert(
                                                            `Could not successfully ${action}. Failed with error: ${status}: ${summary} - ${details}`,
                                                            'danger'
                                                        );
                                                        return error;
                                                    }
                                                );
                                        }
                                        selected.forEach(
                                            (value): void => {
                                                // TODO: error handling
                                                patchSingleStudent(value, action)
                                                    .then(
                                                        (): void => {
                                                            this.setAlert(
                                                                `Selected instances did successfully ${action}.`,
                                                                'success'
                                                            );
                                                        }
                                                    )
                                                    .catch(
                                                        (error: any): Promise<void> => {
                                                            const { status } = error.response.data;
                                                            const { summary } = error.response.data;
                                                            const { details } = error.response.data;
                                                            // TODO: Proper error handling
                                                            this.setAlert(
                                                                `Could not successfully ${action}. Failed with error: ${status}: ${summary} - ${details}`,
                                                                'danger'
                                                            );
                                                            return error;
                                                        }
                                                    );
                                            }
                                        );
                                    } else if (action === 'resetPassword') {
                                        selected.forEach(
                                            (value): void => {
                                                this.resetStudentPasswordWithErrorHandling(value, action);
                                            }
                                        );
                                    } else if (action === 'delete') {
                                        selected.forEach(
                                            (value): void => {
                                                this.deleteStudentWithErrorHandling(value, action);
                                            }
                                        );
                                    }
                                    return null;
                                }}
                            />
                        </Row>
                    </Col>
                    <Col sm={3} lg={2} className="float-right">
                        {labInfo && semesterInfo && (
                            <ExportButton
                                tooltipText="Export all selected students"
                                data={displayData.filter((student): boolean => selected.includes(student.uuid))}
                                filename={`${labInfo.name}-${semesterInfo.name}-Students.csv`}
                                isDisabled={selected.length === 0}
                            />
                        )}
                    </Col>
                </Row>
                {!loading ? (
                <><Row className="mb-5">
                        <DataTable columns={this.columns} displayData={displayData} onRowSelect={this.onRowSelect} />
                    </Row>
                        <ConfirmModal
                            show={showDeleteConfirmation}
                            handleClose={this.hideDeleteModal}
                            action={this.deleteLabsemester}
                            type="delete"
                            itemsForDeletion={semesterInfo && displayData && labGroups
                                ? [
                                    { type: 'Labsemester', name: semesterInfo.name },
                                    ...labGroups.map(
                                        (item): { type: string; name: string; } => ({ type: 'Labgroup', name: item.name })
                                    ),
                                    ...displayData.map(
                                        (item): { type: string; name: string; } => ({ type: 'Student', name: item.token })
                                    ),
                                ]
                                : null} /></>
                ):(<>
                    <Row className="justify-content-center">
                        <Label>
                            <h4>Loading Semesters...</h4>
                        </Label>
                    </Row>
                    <Row className="justify-content-center">
                        <Spinner animation="border" role="status" />
                    </Row>
                </>)}
            </>
        );
    }
}
