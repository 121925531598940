import Axios from 'axios';
import { get as getCookie } from 'es-cookie';

export default function postFeedback(reason: string, details: string, otherReason: string): Promise<void> {
    return Axios.post(
        `${process.env.API_URL}/feedback`,
        { reason, details, otherReason },
        {
            responseType: 'json',
            headers: { Authorization: `Bearer ${getCookie('token')}` },
        }
    ).then();
}
