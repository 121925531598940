import React, { ReactElement } from 'react';
import { Row, Col } from 'reactstrap';
import { Page } from '../../components/App/App';
import PageCard from '../../components/Card/PageCard';
import { AuthContext, AuthContextValue } from '../../components/Auth/AuthContext';

export default function Dashboard({ pages }: { pages: Page[] }): ReactElement {
    return (
        <AuthContext.Consumer>
            {(context: AuthContextValue): React.ReactElement => {
                const { role } = context.state.user;
                return (
                    <>
                        {role === 'teacher' && (
                            <>
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item active">Home</li>
                                </ol>
                            </>
                        )}
                        <Row className="justify-content-center">
                            {pages.map(
                                (page: Page): ReactElement => (
                                    <Col sm={4} key={page.link}>
                                        <PageCard page={page} />
                                    </Col>
                                )
                            )}
                        </Row>
                    </>
                );
            }}
        </AuthContext.Consumer>
    );
}
