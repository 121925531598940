import Axios from 'axios';
import { get as getCookie } from 'es-cookie';

export default function getDoc(): Promise<JSON> {
    return Axios.get(`${process.env.swaggerURL}`, {
        responseType: 'json',
        headers: { Authorization: `Bearer ${getCookie('token')}` },
    }).then(
        (response: any): JSON => {
            const { data } = response;
            return data;
        }
    );
}
